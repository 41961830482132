<template lang="pug">
div(v-if="ready" data-test="core-questions-form")
  FormKit(
    :modelValue="formDataAndFormKitScriptExecutionContext"
    type='group'
    id='coreForm'
  )
    FormKitSchema(
      :schema='schema',
      :data="formDataAndFormKitScriptExecutionContext"
    )
</template>

<script lang="ts">
import { defineComponent, onMounted, ref, watch, Ref, onUnmounted, computed } from 'vue'
import { AxiosErrorWrapper, axiosInstance } from 'src/boot/axios'

import { Schema } from 'src/interfaces/registration'
import * as ilapi from "src/composables/InleagueApiV1"
import { CoreQuestion, Guid, Registration, Season, WithDefinite } from "src/interfaces/InleagueApiV1"
import { copyViaJsonRoundTrip, exhaustiveCaseGuard, useIziToast, __FIXME__UNSAFE_CAST } from 'src/helpers/utils'
import { considerCoreQuestionDisabledForPrimaryRegistration } from "./CoreQuestions"
import { emitsDef, kVirtualQuestionAnswerPrefix, propsDef } from "./core.ilx";
import { User } from 'src/store/User'
import { Client } from 'src/store/Client'

export default defineComponent({
  props: propsDef(),
  emits: emitsDef(),
  setup(props, {emit, attrs}) {
    const iziToast = useIziToast();

    const coreQuestions = ref([]) as Ref<CoreQuestion[]>

    /**
     * A map of questionID->"the magic value that indicates 'show a text input'"
     */
    const magicShowTextInputValueByQuestionID = computed(() => {
      const m = new Map<Guid, string>()
      for (const q of coreQuestions.value) {
        if (q.type === "text" && q.useOptions) {
          m.set(q.id, q.useOptions.arbitraryTextInputWhenValueIs)
        }
      }
      return m;
    })

    /**
     * this is both form data, storing the inputs the user enters
     * (and the form will create the appropriate properties on input if they do not exist, e.g. in the case of no existing registration),
     * as well as data that is required to run the embedded FormKit scripting language code inside the FormKit schema
     */
    const formDataAndFormKitScriptExecutionContext : Ref<Record<string, any>> = (() => {
      switch (props.variant.type) {
        case "actual-form":
          // can be an empty object if there was no existing registration
          const regCopy = ref<Record<string, any>>(copyViaJsonRoundTrip(props.variant.maybeExistingRegistration ?? {}));

          // augment w/ additional info
          // This adds the playerGender property to the form, it didn't exist before
          // This is purely script execution context related (there is no player gender form input)
          regCopy.value['playerGender'] = props.variant.playerDetails.playerGender

          // force coed to 0 if player is not female
          if (props.variant.playerDetails.playerGender !== "G") {
            regCopy.value.coed = 0
          }

          return regCopy;
        case "child-season-centric-preview":
          return ref({});
        case "general-form-preview":
          return ref({});
        default: exhaustiveCaseGuard(props.variant);
      }
    })();

    // Convenient alias for existing registration, to not have to check variant type every time
    // Either there is or there is not an existing registration, regardless of variant type
    const maybeExistingRegistration : Registration | null = (() => {
      if (props.variant.type === "actual-form" && props.variant.maybeExistingRegistration) {
        return props.variant.maybeExistingRegistration;
      }
      return null;
    })();

    const seasonDetails = ref<Season | null>(null)
    const schema = ref([]) as Ref<any[]>
    const ready = ref(false);



    const titles = ref<Record<string, any>>({
    '6DAAF436-AE21-4B79-81E0-BCDBE1861FBE': [{  $el: 'h2',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-t-4 pt-4 border-gray-500 font-medium text-gray-700',
      },
      children: 'General Information'
    }, {$el: 'h2',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-t-4 pt-4 border-gray-500 font-medium text-gray-700',
      },
      if:"$grade>=9",
      children: 'Player Contact Information'
    }],
    '555AA59D-7F83-4002-B7BF-11DE7333DBAA': [{ $el: 'h2',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-t-4 pt-4 border-gray-500 font-medium text-gray-700',
      },
      children: 'Medical Information'
    }],
    '292799AB-3D18-469F-ACE3-D758C3467FD5': [{
      $el: 'h2',
      attrs: {
        class: 'pt-4 border-t-4 border-gray-500 font-medium text-gray-700',
      },
      children: "Doctor's Contact Information"
    },{
      $el: 'p',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-gray-500 italic',
      },
      children: "If you wish your family doctor to be contacted in case of an emergency, please provide his/her name and phone number.",
    }],
    '79229067-1A6A-454C-95BC-CFF049722D9E': [{
      $el: 'h2',
      attrs: {
        class: 'pt-4 border-t-4 border-gray-500 font-medium text-gray-700',
      },
      children: "Emergency Contact Information"
    },{
      $el: 'p',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-gray-500 italic',
      },
      children: "Please list someone who is not a parent/guardian to be contacted in an emergency, in case the family cannot be reached."
    }],
    '73C80599-B154-4B1A-A992-EE04F9167FCB': [{
      $el: 'h2',
      attrs: {
        class: 'mb-4 pb-4 border-b-4 border-t-4 pt-4 border-gray-500 font-medium text-gray-700',
      },
      children: "Program Registration"
    }]
    })

    /**
     * Is this done by the backend?
     */
    const processLabel = (q: Schema) => {
      let processedLabel = q.label
      processedLabel = processedLabel?.replace('{leaguename}', `${Client.value.instanceConfig.shortname}`)
      processedLabel = processedLabel?.replace('{regyear}', `${seasonDetails.value?.registrationYear ?? ""}`)
      if(q.required) processedLabel = `*${processedLabel}`
      if(q.name==='playerEmail') {
        processedLabel= `${processedLabel} (provide ONLY if player is in 9th grade or higher)`
      }

      return processedLabel
    }

    const createValidationParams = (q: Schema) => {
      const validation : string[] = []

      if (q.required) validation.push('required')
      if (q.maxlength) validation.push(`length:2,${q.maxlength}`)
      if (q.validatorFunctionName==="numericValidator") validation.push(`number`)
      if (q.validatorFunctionName==="emailValidator") validation.push(`email`)
      if (q.validatorFunctionName==="phoneNumberValidator") validation.push(`phoneNumber`)

      return validation.join('|')
    }

    const addTopLevelNodeConditionals = (q: any) => {
      const schemaEl = {...q}
      switch(schemaEl.name){
        case('mediProbs'):
          schemaEl.if='$medCond=="1"'
          return schemaEl
        case('coed'):
          schemaEl.if="$playerGender=='G'"
          return schemaEl
        case('playerEmail'):
          schemaEl.if="$grade>=9"
      }

      return schemaEl
    }

    /**
     * Basically, this produces an HTML tree of the form, in a roundabount kind of way.
     * Instead of v-model you must reference values in the form via their string names
     * where properties by those names are expected to be found in `formDataAndFormKitScriptExecutionContext`.
     */
    const processRegistrationSchema = (data: readonly Readonly<CoreQuestion>[]) => {
      const result: {schema: Schema[]} = {
        schema: [],
      }

      const defaultNode = (q: CoreQuestion) : Record<string, any> => {
        return {
          name: q.name,
          'input-has-errors-class': 'border-red-500',
          attrs: {},
          validation:createValidationParams(q),
          "data-test": q.id
        }
      }

      data.forEach((q: CoreQuestion) => {
        if (titles.value[(q.id as string)]) {
          result.schema.push(...titles.value[(q.id as string)])
        }

        let field = defaultNode(q)

        if (q.type==='radio' && (q.label as string).length>55) {
          field['label'] = `*${q.displayName}`
          field['help'] = q.label
          field['help-class']='textBlack'
        } else {
          field['label']= processLabel(q)
        }

        // this assumes that q.type can be "p", is this the case? why? do core questions have type "p"?
        // if so, it needs to be added to the definition of q.type
        if(__FIXME__UNSAFE_CAST<typeof q.type | "p">(q.type) != 'p') {
          field.$formkit = q.type
        } else {
          field.$el='p'
        }
        if (q.html) field.$formkit = q.html

        const disabled = maybeExistingRegistration
          ? considerCoreQuestionDisabledForPrimaryRegistration(maybeExistingRegistration, q, {checkAgainstUserRoles: User.value.roles})
          : false;

        if (disabled) {
          field.disabled = true;
        }

        if (q.options) field.options = q.options
        if(q.autofillDisabled) field.attrs.autoComplete=false
        if(q.type==='select') {

          field.placeholder="Select an Option"
          if(q.options){
            for(let i = 0; i<q.options?.length; i++) {
              if(q.options[i].value==="" && formDataAndFormKitScriptExecutionContext.value[q.name]===undefined) {
                formDataAndFormKitScriptExecutionContext.value[q.name] = ""
              } else if (Number.isInteger(q.options[i].value)) {
                field.options[i].value=field.options[i].value.toString()
              }
            }
          }
        }

        if (q.type === "text") {
          if (q.useOptions) {
            const hasNoOptions = q.useOptions.options.length === 0
            const has1OptionButItIsJustUseArbitraryText = q.useOptions.options.length === 1 && q.useOptions.options[0].value === q.useOptions.arbitraryTextInputWhenValueIs

            if (hasNoOptions || has1OptionButItIsJustUseArbitraryText) {
              field = {
                $formkit: "text",
                name: fkNodeName(q),
                label: processLabel(q),
                validation: createValidationParams(q),
                validationLabel: q.name,
                "data-test": q.id,
                autocomplete: "off",
              }
            }
            else {
              field = {
                children: [
                  // select element writes into "virtual" field. We expect updates to the virtual field to also update the non-virtual field
                  // by way of some watchers we've registered for this purpose.
                  {
                    $formkit: "select",
                    name: fkNodeNameVirtual(q),
                    label: processLabel(q),
                    options: q.useOptions.options,
                    placeholder: "Select an Option",
                    validation: createValidationParams(q),
                    validationLabel: q.name,
                    "data-test": q.id, // tests use locator `select[data-test=...]`
                    autocomplete: "off",
                    onInput: (v: any) => {
                      const virtual = fkNodeNameVirtual(q)
                      if (formDataAndFormKitScriptExecutionContext.value[virtual] === v) {
                        return
                      }

                      formDataAndFormKitScriptExecutionContext.value[virtual] = v;

                      if (v === magicShowTextInputValueByQuestionID.value.get(q.id)) {
                        // transition to the "show the text input instead of the select"
                        formDataAndFormKitScriptExecutionContext.value[q.name] = ""
                      }
                      else {
                        formDataAndFormKitScriptExecutionContext.value[q.name] = v
                      }
                    }
                  },
                  // Non-virtual input; only displayed if the virtual field has some token value.
                  {
                    if: `$${fkNodeNameVirtual(q)} == ${q.useOptions.arbitraryTextInputWhenValueIs}`,
                    $formkit: "text",
                    name: fkNodeName(q),
                    validation: createValidationParams(q),
                    validationLabel: q.name,
                    // don't delete the value out from under us just because the element unmounted
                    preserve: true,
                    "data-test": q.id, // tests use locator `input[data-test=...]`
                    autocomplete: "off",
                    onInput: (v: any) => {
                      formDataAndFormKitScriptExecutionContext.value[q.name] = v
                    }
                  },
                ]
              }
            }
          }
        }

        // radio buttons in the "core questions" area are layed out horizontally
        if (q.type==='radio') {
          field["options-class"] = 'flex flex-row';
          field["option-class"] = 'pr-4';

          // each radio option should have a data-test=`${questionID}` attr
          if (field.options && Array.isArray(field.options)) {
            for (const option of field.options) {
              option.attrs = option.attrs || {};
              option.attrs["data-test"] = q.id;
            }
          }
        }

        field = addTopLevelNodeConditionals(field)

        result.schema.push(field)
      })
      return result
    }

    const getCoreQuestions =  async () => {
      try {
        coreQuestions.value = await (async () => {
          switch (props.variant.type) {
            case "general-form-preview":
              return await ilapi.getCoreQuestions(axiosInstance, {
                asPreview: true
              });
            case "child-season-centric-preview":
              return await ilapi.getCoreQuestions(axiosInstance, {
                asPreview: true,
                seasonUID: props.variant.seasonUID
              });
            case "actual-form":
              return await ilapi.getCoreQuestions(axiosInstance, {
                asPreview: false,
                seasonUID: props.variant.seasonUID,
                childID: props.variant.playerDetails.childID,
                competitionUIDs: props.variant.competitionUIDs,
              });
            default: exhaustiveCaseGuard(props.variant);
          }
        })();

        const processedResponse = processRegistrationSchema(coreQuestions.value)
        schema.value=processedResponse.schema

        emit("notifyCoreQuestionsReceipt", coreQuestions.value);
      } catch (error) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(error);
      }
    }

    watch(formDataAndFormKitScriptExecutionContext, (val) => {
      if(val) {
        emit('updateCoreQuestionAnswers', val)
      }
    }, {deep: true})

    onMounted(async ()=> {
      // maybe pull in season info
      switch (props.variant.type) {
        case "actual-form":
          // fallthrough
        case "child-season-centric-preview": {
          seasonDetails.value = await Client.getSeasonByUID(props.variant.seasonUID) ?? null;
          break;
        }
        case "general-form-preview":
          // we don't have any season information with which to search for a season here
          break;
        default: exhaustiveCaseGuard(props.variant);
      }

      await getCoreQuestions()

      {
        const existingAnswers = props.variant.type === "actual-form"
          ? (props.variant.maybeExistingRegistration ?? {})
          : {}

        formDataAndFormKitScriptExecutionContext.value = {
          ...formDataAndFormKitScriptExecutionContext.value,
          ...defaultVirtualValues(coreQuestions.value, existingAnswers)
        };
      }

      ready.value = true;
    })

    return {
      formDataAndFormKitScriptExecutionContext,
      schema,
      seasonDetails,
      titles,
      ready,
    }

  },
})

function fkNodeName(q: CoreQuestion) : string {
  return q.name
}

function fkNodeNameVirtual(q: CoreQuestion) : string {
  return kVirtualQuestionAnswerPrefix + fkNodeName(q)
}

function needsVirtualField(q: CoreQuestion) : q is WithDefinite<CoreQuestion, "useOptions"> {
  // We need a "virtual" field to track the selected option in addition to the "actual" value,
  // where the selected option is usually exactly the same as the actual value, EXCEPT when
  // the selected option is the "show a text input" value, and then the actual value comes from
  // a text input
  return q.type === "text" && !!q.useOptions
}

function defaultVirtualValues(coreQuestions: CoreQuestion[], existingAnswers: Partial<Registration>) {
  const qsNeedingVirtualFields = coreQuestions.filter(needsVirtualField);

  const attrs : {[virtualNodeName: string]: any} = {}

  for (const q of qsNeedingVirtualFields) {
    const currentAnswer = existingAnswers[q.name as keyof Registration]
    const magicShowTheTextInputValue = q.useOptions.arbitraryTextInputWhenValueIs
    const has1OptionButItIsJustUseArbitraryText = q.useOptions.options.length === 1 && q.useOptions.options[0].value === q.useOptions.arbitraryTextInputWhenValueIs

    if (currentAnswer === undefined) {
      if (q.useOptions.options.length > 0 && !has1OptionButItIsJustUseArbitraryText) {
        attrs[fkNodeNameVirtual(q)] = ""
        attrs[fkNodeName(q)] = ""
      }
      else {
        attrs[fkNodeNameVirtual(q)] = magicShowTheTextInputValue
        attrs[fkNodeName(q)] = ""
      }
    }
    else if (q.useOptions.options.find(v => v.value === currentAnswer)) {
      attrs[fkNodeNameVirtual(q)] = currentAnswer
      attrs[fkNodeName(q)] = currentAnswer
    }
    else {
      attrs[fkNodeNameVirtual(q)] = magicShowTheTextInputValue
      attrs[fkNodeName(q)] = currentAnswer
    }
  }

  return attrs;
}
</script>

<style scoped>
/* /deep/ because we need child components in the rendered FormKitSchema component to have these (scoped) styles */
.il-options-horizontal-layout ::v-deep(.child) {
  display:flex;
  flex-direction: row;
}
.il-options-horizontal-layout > .il-option ::v-deep(.child) {
  margin-left:.5em;
}
</style>
