import { defineComponent, ref, computed, onMounted, onBeforeUnmount, watch } from 'vue'
import { RouterLink } from 'vue-router'

import MasterInvoiceDisplay from 'src/components/Payment/MasterInvoiceDisplay.vue'
import LineItemsDisplay from 'src/components/Payment/LineItemsDisplay.vue'
import TransactionDisplay from 'src/components/Payment/TransactionDisplay.vue'
import { Invoice, LastStatus_t } from 'src/interfaces/Store/checkout'
import { System } from 'src/store/System'

import * as R_Checkout from 'src/components/Payment/pages/R_Checkout.route'
import { AxiosErrorWrapper } from 'src/boot/AxiosErrorWrapper'
import { assertNonNull, assertTruthy, useIziToast, vReqT } from 'src/helpers/utils'
import { getPaymentScheduleBlurb } from 'src/composables/InleagueApiV1.Invoice'
import { axiosInstance } from 'src/boot/AxiosInstances'
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { isSubscriptionInvoice } from '../InvoiceUtils'
import { Integerlike } from 'src/interfaces/InleagueApiV1'

import { CheckoutStore } from "src/store/CheckoutStore"
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faFileInvoice } from '@fortawesome/pro-solid-svg-icons'
import { Btn2 } from 'src/components/UserInterface/Btn2'

export default defineComponent({
  name: 'MasterInvoicePage',
  props: {
    invoiceID: vReqT<Integerlike>(),
  },
  setup(props) {
    // TODO: clarify what this is, some kind of flag?
    const refundInvoice = ref(false)

    const invoiceInstance = computed<Invoice | undefined>(() => {
      return CheckoutStore.value.invoice[props.invoiceID]
    })

    const paymentProcessing = computed(() => {
      return System.value.paymentProcessing
    })

    const refundProcessing = computed(() => {
      return System.value.refundProcessing
    })

    const paymentAttempted = computed(() => {
      return CheckoutStore.value.paymentAttempted
    })

    const processed = computed(() => {
      return CheckoutStore.value.processed
    })

    const onProcessed = () => {
      refundInvoice.value = false
      xRenderKey.value += 1;
    }

    const voidInvoice = async () => {
      try {
        await CheckoutStore.voidInvoice(props.invoiceID as string);
        // reload in voided state for view purposes
        await CheckoutStore.getInvoice({invoiceID: props.invoiceID as string, expand: true});
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }
    }

    const hasRefundPermission = computed(() => {
      return refundable.value
        && invoiceInstance.value
        && invoiceInstance.value.adminAccess
    })

    const showRefundButton = computed(() => {
      return hasRefundPermission.value
        && invoiceInstance.value
        && invoiceInstance.value.transactionBalance > 0
    })

    const processing = computed(() => invoiceInstance.value?.lastStatus === LastStatus_t.IN_FLIGHT);

    const refundable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.PAID_AND_PROCESSED:
        case LastStatus_t.REFUNDED: // n.b. does not consider remaining monetary amounts, that should be done elsewhere
          return true;
        default:
          return false;
      }
    })

    const payable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.NULLISH:
        case LastStatus_t.CREATED:
        case LastStatus_t.PAYMENT_REJECTED:
          return true;
        default:
          return false;
      }
    })

    const voidable = computed(() => {
      switch (invoiceInstance.value?.lastStatus) {
        case LastStatus_t.NULLISH:
        case LastStatus_t.CREATED:
        case LastStatus_t.PAYMENT_REJECTED:
        case LastStatus_t.PROCESSING_ACH:
          return true;
        default:
          return false;
      }
    });

    const checkoutRoute = computed(() => {
      if (invoiceInstance.value) {
        return R_Checkout.routeDetailToRouteLocation({
          invoiceInstanceIDs: [invoiceInstance.value.instanceID]
        })
      }
      return null;
    })

    watch(
      processed,
      (value: { [key: string]: boolean }) => {
        // console.log('processed watcher', value)
        if (value[props.invoiceID as string]) {
          onProcessed()
        }
      },
      { deep: true }
    )

    onMounted(async () => {
      if (!paymentProcessing.value) {
        // console.log('in mounted')
        await CheckoutStore.getInvoice({
          invoiceID: props.invoiceID as string,
          expand: true,
        })

        const invoice = CheckoutStore.value.invoice[props.invoiceID]
        const transactions = invoice?.transactions;

        assertTruthy(invoice, "side effect of `getInvoice`")
        assertNonNull(transactions, "expected shape")
      }
    })

    onBeforeUnmount(async () => {
      await CheckoutStore.setPaymentAttempted(false)
    })

    const paymentScheduleBlurb = ref<string | undefined>(undefined);

    const getOrRefreshPaymentScheduleBlurb = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        paymentScheduleBlurb.value = await (async () => {
          if (invoiceInstance.value && isSubscriptionInvoice(invoiceInstance.value)) {
            return await getPaymentScheduleBlurb(axiosInstance, {invoiceInstanceID: props.invoiceID})
          }
          else {
            return undefined;
          }
        })();
      })
    }

    const onCompletedRefund = () => {
      CheckoutStore.getInvoice({invoiceID: props.invoiceID.toString(), expand: true})
      xRenderKey.value += 1;
    }

    watch(
      [() => invoiceInstance.value?.instanceID, () => invoiceInstance.value?.invoiceID, () => invoiceInstance.value?.methodID],
      getOrRefreshPaymentScheduleBlurb,
      {immediate: true}
    )

    // There's a few components that have state constructed via setup/onMounted where really this component should own that state.
    // This is intended to force re-run the setup/onMounted cycles of those components.
    const xRenderKey = ref(0)

    return () => {
      if (!invoiceInstance.value) {
        return null;
      }

      return (
        <div class="flex flex-col">
          <div class={paymentProcessing.value || refundProcessing.value ? "hidden" : ""}>
            <div class="flex justify-between md:items-end">
              <div class="flex flex-col md:flex-row">
                <h1 class="text-l font-medium flex items-center">
                  <FontAwesomeIcon class="mr-2" icon={faFileInvoice}/>
                  Invoice # {invoiceInstance.value.instanceID}
                </h1>
                {invoiceInstance.value.stripe_invoiceNumber
                  ? <div class="ml-2 text-base flex items-center">
                    ({invoiceInstance.value.stripe_invoiceNumber})
                  </div>
                  : null}
              </div>

              {voidable.value
                ? <Btn2 class="px-2 py-1" onClick={voidInvoice} data-test="void-button">Void Invoice</Btn2>
                : null}

              <div class="flex flex-col gap-2 items-end">
                {showRefundButton.value
                  ? <Btn2
                    class="px-2 py-1"
                    onClick={() => { refundInvoice.value = !refundInvoice.value }}
                    data-test="refund"
                  >
                    Refund Options
                  </Btn2>
                  : null}
              </div>
            </div>

            {paymentAttempted.value && invoiceInstance.value.status === "draft"
              ? <div class="px-16 py-4">
                <h3 class="italic text-center">
                  Your payment was accepted, but we are temporarily unable to process the invoice.
                  Please contact support if you have not received payment confirmation within an hour.
                </h3>
              </div>
              : null
            }

            <MasterInvoiceDisplay
              key={`masterInvoiceDisplay/${xRenderKey}`}
              invoice={invoiceInstance.value}
            />

            <LineItemsDisplay
              key={`lineItemsDisplay/${xRenderKey}`}
              class="m-6 md:-mx-6 md:my-0"
              invoice={invoiceInstance.value}
              masterInvoice={true}
              refund={refundInvoice.value}
              onProcessed={onProcessed}
              onCompletedRefund={onCompletedRefund}
              showDueNow={false}
              paymentScheduleBlurb={paymentScheduleBlurb.value}
            />

            {!paymentAttempted.value && checkoutRoute.value && payable.value
              ? <div class="flex w-full justify-end">
                  <RouterLink to={checkoutRoute.value}>
                    <Btn2 class="mt-2 px-2 py-1" data-test="goToPayment-button">
                      Proceed to Payment
                    </Btn2>
                  </RouterLink>
              </div>
              : null}

            {processing.value
              ? <div class="mt-4 text-sm flex justify-center">
                A payment request for this invoice is actively being processed.
              </div>
              : null}

            {invoiceInstance.value.transactions && invoiceInstance.value.transactions.length
              ? <TransactionDisplay
                transactions={invoiceInstance.value.transactions}
              />
              : null}
          </div>
        </div>
      )
    }
  },
})
