import { type AxiosInstance } from 'axios'
import {
  ContentChunkI,
  ContentChunkRequestInterface,
} from 'src/interfaces/Store/contentChunks'
import * as ilapi from "src/interfaces/InleagueApiV1"

import { System } from 'src/store/System'
import { ref } from 'vue'

export const ContentChunkStore = (() => {
  const state = ref(freshState());

  async function getContentChunk(ax: AxiosInstance, details: ContentChunkRequestInterface) {
    const getParams : Readonly<Record<string, any>> = (() => {
      let getParams : Record<string, any>;
      if (details.interpolationArgs) {
        getParams = {...details.interpolationArgs, withInterpolation: true};
      }
      else {
        getParams = {withInterpolation: false};
      }

      if (details.override === false) {
        getParams.override = false;
        getParams.obeyDates = false;
      }

      return getParams;
    })();

    // the url like foo/bar/:chunkNumericIdOrStringLabel gets rough if stringLabel contains a '/'
    // So, don't create chunks that contain '/' in the string label
    const response = await ax.get(`v1/interpolatedContentChunk/${details.id}`, {params: getParams});

    const localContent: ilapi.ContentChunk = response.data.data

    if (localContent.disableRich === '') {
      localContent.disableRich = 0
    }

    setContent(localContent)

    System.directCommit_setLoading(false);

    return localContent
  }

  async function getContentChunks(ax: AxiosInstance, ids: number[]) {
    try {
      let formattedIds = '?IDs='
      for (let i = 0; i < ids.length; i++) {
        formattedIds += `${ids[i]},`
      }

      const response = await ax.get(
        `v1/contentChunks${formattedIds}`
      )

      const contentChunks = response.data.data

      for (let i = 0; i < contentChunks.length; i++) {
        state.value.content[contentChunks[i].id] = contentChunks[i]
        if (state.value.content[contentChunks[i].id].disableRich === '') {
          // found empty string'
          state.value.content[contentChunks[i].id].disableRich = 0
        }
      }
      setLoadingContentChunks(false)
      return contentChunks
    } catch (error) {
      setLoadingContentChunks(false)
      return error
    }
  }

  function setContent(contentChunk: ilapi.ContentChunk) {
    // We map both the id and the canonicalized shortlabel to the content chunk
    // Some lookups are by number, some are by shortlabel
    // Code doing lookups must canonicalize their lookup keys
    state.value.content[contentChunk.id] = contentChunk
    state.value.content[contentChunk.shortLabel.toLowerCase().trim()] = contentChunk
  }

  function setLoadingContentChunks(bool: boolean) {
    state.value.loadingContentChunks = bool
  }

  return {
    get value() { return state.value },
    getContentChunk,
    getContentChunks,
    setLoadingContentChunks,
    setContent
  }
})();

function freshState(): ContentChunkI {
  return {
    content: {} as Record<string, ilapi.ContentChunk>,
    loadingContentChunks: true
  }
}
