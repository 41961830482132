import { AxiosInstance } from "axios";
import { rpc } from "src/composables/Rpc";
import { Guid, Integerlike } from "src/interfaces/InleagueApiV1";

/**
 * api types, __not__ stripejs browser-types. There is a difference.
 * We're getting objects from the backend that are "raw" api types.
 * StripeJS (which is NOT what this import is) types are a modified version of the "real" api types.
 * Typically, on the frontend, we want to use the StripeJS/StripeElements types/values, but
 * not here, where we want to reflect what the backend will be sending us.
 */
import type { Stripe } from "stripe";
export type { Stripe }

export const stripe_getPaymentIntentForInvoice = rpc<{invoiceInstanceID: Integerlike}, Stripe.PaymentIntent>("get", "v1/customer/stripe_getPaymentIntent")

/**
 * our own backend may stitch on a setupintent if there is a relevant setupintent for the paymentMethod,
 * at least in the case of a "paymentMethod not yet directly linked to a customer but it has a setupIntent linked to the customer that is waiting on ach microdeposit verification"
 */
export type IL_PaymentMethod = Stripe.PaymentMethod & {
  setupIntent?: Stripe.SetupIntent,
}

export async function stripe_listCustomerPaymentMethods(ax: AxiosInstance, args: {clientGatewayID: Guid}) : Promise<{
  stripeAccountID: string,
  paymentMethods: IL_PaymentMethod[],
  ach: {
    enabled: boolean,
    recentInstantVerifications: number,
    remainingInstantVerifications: number,
    connectAccountMerchantOfRecord: string,
  }
}> {
  const response = await ax.get(`v1/payments/${args.clientGatewayID}/paymentMethods`, {params: {v: 2}});
  return response.data.data;
}

export const stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal = rpc<{clientGatewayID: Guid}, Promise<Stripe.SetupIntent>>("post", "v1/customer/stripe_createAchSetupIntent_forClientBankAccountInfoCollectionModal");
export const stripe_createAchSetupIntent_manualAcctNumber = rpc<{clientGatewayID: Guid, bankAccount_paymentMethodID: string}, Promise<Stripe.SetupIntent>>("post", "v1/customer/stripe_createAchSetupIntent_manualAcctNumber");
export const stripe_deleteSetupIntentPendingAchVerification = rpc<{clientGatewayID: Guid, setupIntentID: Guid}, void>("post", "v1/customer/stripe_deleteSetupIntentPendingAchVerification")
export const stripe_trackMultiUseActiveMandate = rpc<{clientGatewayID: Guid, setupIntentID: Guid}, void>("post", "v1/customer/stripe_trackMultiUseActiveMandate")

export function stripeObjId<T extends null | {id: string} | string>(v: T) : T extends null ? (string | null) : string {
  if (v === null) {
    return null as any
  }
  else {
    return (typeof v === "object" ? v.id : v) as any
  }
}
