<template lang="pug">
div(v-if="invoice && ready")
  .mt-6.flex.flex-col
    .-my-2.py-2.overflow-x-auto(class='md:-mx-6 md:px-6 lg:-mx-8 lg:px-8')
      .align-middle.inline-block.min-w-full.shadow.overflow-hidden.border-b.border-gray-200(
        class='sm:rounded-lg md:min-w-full'
      )
  table.min-w-full.table-fixed(class='md:min-w-full')
    thead
      tr.grid(:class='{ "grid-cols-9": refund, "grid-cols-8": !refund }')
        .px-2.border-b.border-gray-200.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.col-span-1.flex.justify-center(
          v-if='refund'
        )
          t-checkbox(v-model='refundAll', @input='toggleRefund')
        .px-6.py-3.border-b.border-gray-200.bg-gray-50.text-left.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.col-span-5(
          class='md:col-span-6'
        )
          | Description
        .px-12.py-3.border-b.border-gray-200.bg-gray-50.text-center.text-xs.leading-4.font-medium.text-gray-500.uppercase.tracking-wider.col-span-3(
          class='md:col-span-2'
        )
          | Amount
    tbody.bg-white
      tr
        .grid(
          :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }',
          v-for='lineItem in invoice.lineItems',
          :key='lineItem.lineItemID',
          data-cy='registrant'
        )
          .col-span-1.px-2.py-2.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200.flex.justify-center(
            v-if='refund && lineItem.adminAccess && (lineItem.amountPaidBalance > 0 || !lineItem.canceled)'
          )
            t-checkbox(
              :xDataTest="`refundItemCheckBox/lineItemID=${lineItem.lineItemID}`"
              v-model='refundItems[lineItem.lineItemID]',
              @input='toggleRefundItem(lineItem.lineItemID)'
            )
          .col-span-1.px-2.py-2.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200.flex.justify-center(
            v-else-if='refund'
          )
          .col-span-5.px-6.py-4.text-sm.leading-5.text-gray-500.border-b.border-gray-200.font-medium(
            class='md:col-span-6'
          )
            div {{ lineItem.description }}

            //-
            //- does this invoice reference event signup X, but the "actual" signup is Y?
            //-
            div(v-if="lineItem.eventSignup_movedTo" class="shadow-sm border border-slate-200 p-1")
              div(class="text-gray-800")
                div This event signup was moved, but the invoice remains relevant.
                div(class="text-xs") Cancelations performed from here will cancel the "moved-to" event signup.
              div(class="border-t border-slate-200 my-1")
              div(style="display:grid; grid-template-columns: min-content 1fr;" class="text-gray-700")
                div(class="flex") Moved&nbsp;to:
                div(class="flex  ml-2") {{ lineItem.eventSignup_movedTo.eventName }}
                div(class="flex") Moved&nbsp;by:
                div(class="flex  ml-2") {{ lineItem.eventSignup_movedTo.movedBy_firstName }} {{ lineItem.eventSignup_movedTo.movedBy_lastName }}
                div(class="flex") Moved&nbsp;on:
                div(class="flex  ml-2") {{ dayjsFormatOr(lineItem.eventSignup_movedTo.movedDate, "MMM DD YYYY @ h:mm a") }}
                div(class="flex") Comment:
                div(class="flex  ml-2") {{ lineItem.eventSignup_movedTo.comment || "None" }}

          .px-12.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200.flex.justify-end.col-span-3.font-medium(
            class='pr-2/5 md:col-span-2'
          )
            div
              | ${{ parseFloatOrFail(lineItem.amount).toFixed(2) }}
          .col-span-1.border-b.border-gray-200(
            v-if='(lineItem.couponID || lineItem.scholarshipID) && refund'
          )
          .col-span-5.pl-16.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200(
            class='md:col-span-6',
            v-if='lineItem.couponID || lineItem.scholarshipID',
            data-cy='scholarship'
          )
            | {{ lineItem.scholarshipID ? 'Scholarship' : `Coupon: ${lineItem.coupon.couponCode}` }}
          .px-12.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200.flex.justify-end.col-span-3(
            v-if='lineItem.couponID || lineItem.scholarshipID',
            class='pr-2/5 md:col-span-2',
            data-cy='scholarshipAmount'
          )
            | - ${{ lineItem.discount.toFixed(2) }}
          .col-span-1.pl-4.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200(
            v-if='refundItems[lineItem.lineItemID] && refund && !(lineItem.finalAmount === lineItem.creditNoteSum && lineItem.canceled)'
          )
          .col-start-2.col-span-8.pl-4.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200(v-if='refundItems[lineItem.lineItemID] && refund && lineItem.adminAccess && !(lineItem.finalAmount === lineItem.creditNoteSum && lineItem.canceled) && lineItem.entity_type != "qDonation"', data-cy='refundChoices')
            .m-2.w-40(v-if='lineItem.finalAmount != lineItem.creditNoteSum && lineItem.finalAmount > 0 && !lineItem.canceled')
              t-singleRadio(
                label='Refund and Cancel',
                :modelValue='refundActions[lineItem.lineItemID]',
                value='refundAndCancel',
                @change='value => { toggleActionSelected(value, lineItem.lineItemID); }',
                @input='value => { toggleActionSelected(value, lineItem.lineItemID); }',
                v-if='lineItem.finalAmount != lineItem.creditNoteSum && lineItem.finalAmount > 0',
                data-cy='refundAndCancel'
              )
              FormKit.pl-5(
                v-if='refundActions[lineItem.lineItemID] === "refundAndCancel"',
                :label='`Refund Amount ${mungedRefundHoldback.maybeRefundLabelAnnotation}`',
                v-model='refundAmounts[lineItem.lineItemID]',
                :validation='`number|max:${determineMaxRefund(lineItem)}|min:0|required`',
                data-test='refundAmount'
              )
            .m-2.w-40(v-if='lineItem.finalAmount != lineItem.creditNoteSum && lineItem.finalAmount > 0')
              t-singleRadio(
                label='Refund Only',
                :modelValue='refundActions[lineItem.lineItemID]',
                value='refund',
                @change='value => { toggleActionSelected(value, lineItem.lineItemID); }',
                @input='value => { toggleActionSelected(value, lineItem.lineItemID); }',
                v-if='lineItem.finalAmount != lineItem.creditNoteSum && lineItem.finalAmount > 0',
                data-cy='refundOnly'
              )
              FormKit.pl-5(
                v-if='refundActions[lineItem.lineItemID] === "refund"',
                :label='`Refund Amount ${mungedRefundHoldback.maybeRefundLabelAnnotation}`',
                v-model='refundAmounts[lineItem.lineItemID]',
                :validation='`number|min:0|max:${determineMaxRefund(lineItem)}|required`',
                data-cy='refundOnlyAmount'
              )
            t-singleRadio.m-2.w-40(
              v-if='refund && !lineItem.canceled',
              label='Cancel Only',
              :modelValue='refundActions[lineItem.lineItemID]',
              value='cancel',
              @change='value => { toggleActionSelected(value, lineItem.lineItemID); }',
              @input='value => { toggleActionSelected(value, lineItem.lineItemID); }',
              data-test='cancelOnly'
            )
            .w-80(v-if='lineItem.entity_type === "qCompetitionRegistration"')
              //-
              //- "cancel payment plan" is actually a "per invoice" option, but we put it in the "per line item" grouping under
              //- the assumption that a "subscription" invoice always has exactly 1 lineItem.
              //-
              FormKit(
                v-if="isSubscriptionInvoice"
                type='checkbox',
                :label='`Cancel Payment Plan Future Payments ${!invoice.hasActiveSubscription ? "(Payment Plan is Already Canceled)" : ""}`',
                v-model='cancelSubscription',
                :disabled="!invoice.hasActiveSubscription"
                data-test="cancelSubscriptionCheckbox"
              )
              FormKit(
                type='checkbox',
                label='Mark as Never Played',
                v-model='competitionRefund[lineItem.lineItemID].markPlayerAsNeverPlayed',
                value='markPlayerAsNeverPlayed'
              )
              FormKit(
                type='checkbox',
                label='Notify DivisionDirector and Head Coaches',
                v-model='competitionRefund[lineItem.lineItemID].notifyDivisionDirectorAndHeadCoaches',
                value='notifyDivisionDirectorAndHeadCoaches'
              )
              FormKit(
                type='checkbox',
                label='Notify Competition Managers',
                v-model='competitionRefund[lineItem.lineItemID].notifyCompetitionManagers',
                value='notifyCompetitionManagers'
              )
              label.font-medium.text-gray-700.mt-8 Additional Email Message
              textarea.t-input.rounded-md.shadow-sm.text-black.block.w-full(v-model='competitionRefund[lineItem.lineItemID].addlEmailMsg')
          .col-start-4.col-span-8.pl-4.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200(v-else-if='refundItems[lineItem.lineItemID] && refund && lineItem.adminAccess && !(lineItem.finalAmount === lineItem.creditNoteSum && lineItem.canceled)')
            .w-96
              FormKit.pl-5(
                label='Refund Amount',
                v-model='refundAmounts[lineItem.lineItemID]',
                :validation='`number|min:0|max:${determineMaxRefund(lineItem)}|required`',
                data-cy='refundOnlyAmount'
              )

        //-
        //- installment plan ("subscription invoice") information
        //-
        .grid(
          v-if="isSubscriptionInvoice"
          :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }',
        )
          .col-span-5.px-6.py-4.text-sm.leading-5.text-gray-500.border-b.border-gray-200.font-medium(
            class='md:col-span-6'
          )
            div Installment schedule
          .px-12.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200.flex.justify-end.col-span-3.font-medium(
            class='pr-2/5 md:col-span-2'
          )
            div(class="text-right")
              | {{ paymentScheduleBlurb }}

        div(v-if='refund')
          h2.text-4xl.font-medium.text-black.my-2
            font-awesome-icon(:icon='["fas", "cog"]')
          .pl-4.flex.flex-col.col-span-9.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.border-b.border-gray-200
            h2.text-l.font-medium.text-black.my-2 Refund Details
            FormKit.my-2(
              :disabled="reconciliationOnly"
              type='checkbox',
              label='Refund as Check',
              v-model='checkRefund'
            )
            FormKit.my-2(
              v-if="authZ_reconciliationOnly"
              :disabled="disableReconciliationOnly"
              type='checkbox',
              label='Reconciliation Only (note: sends email to original payer about a manual refund)',
              v-model='reconciliationOnly'
              data-test="reconciliationOnly"
            )
            FormKit.my-2.w-56(
              v-if='checkRefund',
              :disabled="reconciliationOnly"
              type='text',
              label='Check No. (optional)',
              v-model='checkNum'
            )
            FormKit.my-2.w-56(
              type='textarea',
              label='Registrar Notes',
              v-model='refundMsg'
              :validation="fk_registrarNotesValidation"
              data-test="registrarNotes"
            )
            TBtn.justify-center(type='submit' data-test='submitRefund' @click='submitRefund')
              span.w-full.text-center Submit Refund
      div(name='applyCoupon', @submit='applyCoupon', class='md:mx-6')
        tr.bg-gray-60.grid.border-b.border-gray-200(
          v-if='showCouponInput'
          :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }'
        )
          .col-span-3.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-500.flex.justify-start.items-end.h-full(
            class='md:justify-end md:items-center',
            :class='{ "md:col-span-7": refund, "md:col-span-6": !refund }'
          )
            | Coupon Code:
          .whitespace-nowrap.text-sm.leading-5.text-gray-900.font-bold.col-span-5(
            class='md:col-span-2'
          )
            .flex.items-center.mx-2.justify-end.h-full(class='md:justify-between')
              t-input.w-56(v-model='couponCode', data-test='couponCode')
              button.ml-1.inline-flex.items-center.text-center.border.border-transparent.text-xs.leading-5.rounded-md.p-1.text-white.bg-green-700(
                type="button"
                @click="applyCoupon"
                class='hover:bg-green-700 focus:outline-none focus:border-green-700 focus:ring-green h-1/2',
                data-test='applyCoupon'
              )
                | Apply
      tr.bg-gray-50.grid.border-b.border-gray-200(
        v-if='showDueNow && !refund',
        :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }'
      )
        .col-span-5.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-900.flex.justify-end.font-medium.text-gray-500.uppercase.tracking-wider(
          class='md:col-span-6',
          :class='{ "col-span-7": refund, "md:col-span-6": !refund }'
        )
          | Total:
        .px-12.py-4.whitespaceno-wrap.text-sm.leading-5.text-gray-900.flex.justify-end.col-span-3(
          class='pr-2/5 md:col-span-2'
        )
          template(v-if="!isSubscriptionInvoice")
            | ${{ invoice.lineItemSum }}
          template(v-else)
            div
              div ${{ paymentScheduleBlurb }}
              div(class="mt-2") First payment collected upon submit
      tr.bg-gray-50.border-b.border-gray-200.grid(
        v-if='masterInvoice && !refund',
        :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }'
      )
        .col-span-5.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-900.flex.justify-end.font-medium.text-gray-500.uppercase.tracking-wider(
          :class='{ "md:col-span-7": refund, "md:col-span-6": !refund }'
        )
          | Paid:
        .px-12.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-900.flex.justify-end.col-span-3(
          class='pr-2/5 md:col-span-2'
        )
          | {{ invoice.transactionBalance ? `- $${invoice.transactionBalance}` : `$0.00` }}
      tr.bg-gray-50.grid.grid-cols-4.border-b.border-gray-200(
        v-if='masterInvoice && !refund',
        :class='{ "grid-cols-9": refund, "grid-cols-8": !refund }',
        data-cy='balance'
      )
        .col-span-5.px-6.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-900.font-bold.flex.justify-end.font-medium.text-gray-500.uppercase.tracking-wider(
          class='md:col-span-3',
          :class='{ "md:col-span-7": refund, "md:col-span-6": !refund }'
        )
          |
          | Balance:
        .px-12.py-4.whitespace-nowrap.text-sm.leading-5.text-gray-900.font-bold.justify-end.col-span-1(
          class='md:justify-end pr-2/5' data-test="balance"
        )
          div ${{ invoice.transactionBalance ? (invoice.lineItemSum - invoice.transactionBalance).toFixed(2) : invoice.lineItemSum }}
          //- "to be paid on waitlist removal" works 'for now' -- if "hasSomeLineItemWithBlockedPaymentIntent" is true,
          //- then, currently, it implies the invoice is for a competition registration that is on a waitlist where the
          //- associated competitionSeason's waitlist strategy is "GENERATES_TENTATIVE_PAYMENT_INTENT".
          //- But the overall design indicates that other reasons for "hasSomeLineItemWithBlockedPaymentIntent" will eventually exist.
          div(v-if="hasSomeLineItemWithBlockedPayment && lineItemSums.postDiscount > 0") (to be paid on removal from waitlist)
</template>

<script lang="ts">
import { defineComponent, ref, computed, PropType, Ref, onMounted, watch } from 'vue'
import { useRoute } from 'vue-router'


import { v4 as uuidv4 } from 'uuid'
import {
  Invoice,
  LastStatus_t,
  LineItem,
  RefundLineItems,
  RefundOptions,
} from 'src/interfaces/Store/checkout'
import type { AxiosInstance } from 'axios'
import { AxiosErrorWrapper, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'src/boot/axios'
import type { CompetitionRegistration, Floatlike, Integerlike } from 'src/interfaces/InleagueApiV1'
import * as ilapi from "src/composables/InleagueApiV1"
import { dayjsFormatOr } from "src/helpers/formatDate"
import { arraySum, assertNonNull, assertTruthy, checkedObjectValues, parseFloatOr, parseFloatOrFail, parseIntOrFail, requireNonNull, unsafe_objectKeys, useIziToast, vOptT, vReqT } from 'src/helpers/utils'
import { System } from 'src/store/System'
import * as InvoiceUtils from "./InvoiceUtils"
import { CheckoutStore } from "src/store/CheckoutStore"
import { User } from 'src/store/User'

export default defineComponent({
  name: 'InvoiceLineItemDisplay',
  props: {
    invoice: { type: Object as PropType<Invoice>, required: true },
    /**
     * todo:
     *   - true means ???
     *   - false means ???
     * Looks like this was intended to mean "caller is the MasterInvoice component",
     * but there are currently non-MasterInvoice component callers that set this to true.
     */
    masterInvoice: { type: Boolean, required: true },
    showDueNow: {
      type: Boolean,
      default: true,
    },
    refund: Boolean,
    /**
     * if not provided, we do not display the "apply coupon" UI
     */
    doApplyCoupon: vOptT<(_: {invoiceInstanceID: Integerlike, couponCode: string}) => Promise<void>>(),
    /**
     * should be truthy if the invoice is a subscription invoice
     */
    paymentScheduleBlurb: vReqT<string | undefined>(),
  },
  emits: {
    processed: (_: boolean) => true,
    /**
     * This component is currently responsible for performing the refund, and alerts parents
     * if it has completed doing so. Ideally we would notify the parent that we request a refund
     * be performed.
     */
    completedRefund: () => true,
  },
  setup(props, { emit }) {
    const iziToast = useIziToast();
    const coupon = ref(false)
    const couponCode = ref('')

    /**
     * Is every line item checked as refundable?
     * Can get into sorta unexpected states, where it is true, but "non-default checkable" items still aren't checked.
     */
    const refundAll = ref(true)

    // Is some item checked "yes please refund this item"
    const refundItems = ref({}) as Ref<{ [invoiceLineItemID: string | number]: boolean }>

    type RefundAction = "cancel" | "refundAndCancel" | "refund"
    const refundActions = ref({}) as Ref<{[lineItemID: Integerlike]: RefundAction}>
    const refundAmounts = ref({}) as Ref<{[lineItemID: Integerlike]: /*numberlike?*/ string}>

    interface CompRegRefundOptions {
      markPlayerAsNeverPlayed: boolean,
      notifyDivisionDirectorAndHeadCoaches: boolean,
      notifyCompetitionManagers: boolean,
      addlEmailMsg: string
    }

    const competitionRefund = ref({}) as Ref<{[lineItemID: Integerlike]: Partial<CompRegRefundOptions>}>
    const cancelData = ref({}) as Ref<{ [key: string]: string | boolean }>
    const cancelSubscription = ref(true);
    const reconciliationOnly = ref(false);

    // does user have permission for reconciliationOnly
    const authZ_reconciliationOnly = computed(() => User.isInleague);

    // does it make sense to offer reconciliationOnly (or rather, does it _not_ make sense to do so)
    const disableReconciliationOnly = computed(() => {
      const DISABLE = true;
      const NO_DISABLE = false;

      if (!authZ_reconciliationOnly.value) {
        return DISABLE;
      }

      const vals = checkedObjectValues<RefundAction>(refundActions.value)
      if (vals.find(v => v === "cancel")) {
        return DISABLE;
      }

      return NO_DISABLE
    });

    watch(() => disableReconciliationOnly.value, () => {
      if (disableReconciliationOnly.value) {
        reconciliationOnly.value = false
      }
    })

    watch(() => props.invoice.hasActiveSubscription, () => {
      if (!props.invoice.hasActiveSubscription) {
        cancelSubscription.value = false;
      }
    }, {immediate: true})
    watch(() => reconciliationOnly.value, () => {
      if (reconciliationOnly.value) {
        checkRefund.value = false;
      }
    })

    const fk_registrarNotesValidation = computed(() => {
      if (reconciliationOnly.value) {
        // in the `reconciliationOnly` case, refundMsg ends up in transaction description
        // with some length restrictions. We know the server will always prepend "(manually-reconciled) ".
        const serverWillPrepend = "(manually-reconciled) ";
        return [["length", 0, 500 - serverWillPrepend.length]]
      }
      else {
        // other cases have no length requirements
        return []
      }
    })

    const checkRefund = ref(false)
    const checkNum = ref('')
    const refundMsg = ref('')
    const lineItemSums = computed(() => {
      return {
        preDiscount: arraySum(props.invoice.lineItems.map(li => parseFloatOr(li.amount, 0))),
        postDiscount: arraySum(props.invoice.lineItems.map(li => parseFloatOr(li.finalAmount, 0))),
      }
    })

    // This component strongly assumes that the line items we are displaying are for a qCompetitionRegistration, which is necessarily associated with a single (season, competition)
    // If the above assumption is true, we will retrieve a CompetitionSeason object in onMounted and pull out the refund holdback info
    const mungedRefundHoldback = ref<MungedRefundHoldback>(defaultNilMungedRefundHoldback())


    const route = useRoute()

    const isSubscriptionInvoice = computed<boolean>(() => {
      return InvoiceUtils.isSubscriptionInvoice(props.invoice)
    });

    const showCouponInput = computed(() => {
      return (props.invoice.lastStatus === LastStatus_t.NULLISH || props.invoice.lastStatus === LastStatus_t.CREATED || props.invoice.lastStatus === LastStatus_t.PAYMENT_REJECTED)
        && !props.invoice.isExpired // an expired invoice shouldn't get coupons applied to it
        && props.masterInvoice // when is this false?
        && props.doApplyCoupon // parent component needs to have provided us a mechanism to actually redeem the coupon
        && route.name != "master-invoice" // what are the possible expected route names here?
        && lineItemSums.value.preDiscount > 0
    });

    const refund = computed(() => {
      return props.refund
    })

    const hasSomeLineItemWithBlockedPayment = computed(() => {
      return props.invoice.lineItems.some(lineItem => lineItem.paymentBlock_isBlocked);
    })

    const determineMaxRefund = (lineItem: LineItem) : string => {
      return lineItem.amountPaidBalance.toFixed(2);
    }

    const initialRefundSuggestion = (lineItem: LineItem) : string => {
      if (lineItem.entity_type === 'qCompetitionRegistration') {
        return Math.max(
          0, lineItem.amountPaidBalance - mungedRefundHoldback.value.value
        ).toFixed(2);
      } else {
        return lineItem.amountPaidBalance.toFixed(2);
      }
    }

    /**
     * Formalize which line items are "auto refund candidates", i.e. which are auto-checked by default
     */
    const lineItemIsConsideredRefundableByDefault = (v: LineItem) => {
      //
      // Users can still manually check donation line items, but most people don't want donations checked by default.
      //
      switch (v.entity_type) {
        case "qDonation": return false;
        case "qDonation_PartialityShim": return false;
        default: return true;
      }
    }

    const initState = () => {
      for (let i = 0; i < props?.invoice.lineItems?.length; i++) {

        refundItems.value[props.invoice.lineItems[i].lineItemID] =
          refundAll.value && lineItemIsConsideredRefundableByDefault(props.invoice.lineItems[i])
        if (
          props.invoice.lineItems[i].finalAmount !=
            props.invoice.lineItems[i].creditNoteSum &&
          props.invoice.lineItems[i].finalAmount > 0 &&
          !props.invoice.lineItems[i].canceled
        ) {
          refundActions.value[props.invoice.lineItems[i].lineItemID] =
            'refundAndCancel'
        } else if (
          props.invoice.lineItems[i].finalAmount !=
            props.invoice.lineItems[i].creditNoteSum &&
          props.invoice.lineItems[i].finalAmount > 0 &&
          props.invoice.lineItems[i].canceled
        ) {
          refundActions.value[props.invoice.lineItems[i].lineItemID] = 'refund'
        } else if (!props.invoice.lineItems[i].canceled) {
          refundActions.value[props.invoice.lineItems[i].lineItemID] = 'cancel'
        }
        if (
          props.invoice.lineItems[i].entity_type === 'qCompetitionRegistration'
        ) {
          competitionRefund.value[props.invoice.lineItems[i].lineItemID] ||= {}
          competitionRefund.value[
            props.invoice.lineItems[i].lineItemID
          ].markPlayerAsNeverPlayed = false
          competitionRefund.value[
            props.invoice.lineItems[i].lineItemID
          ].notifyDivisionDirectorAndHeadCoaches = false
          competitionRefund.value[
            props.invoice.lineItems[i].lineItemID
          ].notifyCompetitionManagers = false
          competitionRefund.value[
            props.invoice.lineItems[i].lineItemID
          ].addlEmailMsg = ''
        }
        refundAmounts.value[props.invoice.lineItems[i].lineItemID] =
          initialRefundSuggestion(props.invoice.lineItems[i])
        cancelData.value[props.invoice.lineItems[i].lineItemID] = true
      }
    }

    const toggleActionSelected = (value: RefundAction, lineItemID: Integerlike) => {
      refundActions.value[lineItemID] = value
    }

    const applyCoupon = async () => {
      if (props.doApplyCoupon && couponCode.value) {
        await props.doApplyCoupon({invoiceInstanceID: props.invoice.instanceID, couponCode: couponCode.value})
      }
    }

    const getRefundDetails = () => {
      let lineItems: RefundLineItems[] = []
      for (const key of unsafe_objectKeys(refundActions.value)) {
        if (!refundItems.value[key]) {
          continue;
        }

        let lineItem = {
          lineItemID: key,
        } as {
          lineItemID: string
          cancel: boolean
          amount: string
        }
        if (refundActions.value[key] === 'cancel' && refundItems.value[key]) {
          lineItem.cancel = true
          lineItem.amount = '0'
        } else if (
          refundActions.value[key] === 'refundAndCancel' &&
          refundItems.value[key]
        ) {
          lineItem.cancel = true;
          lineItem.amount = refundAmounts.value[key];
        } else if (
          refundActions.value[key] === 'refund' &&
          refundItems.value[key]
        ) {
          lineItem.cancel = false
          lineItem.amount = refundAmounts.value[key]
        }
        if (competitionRefund.value[key]) {
          lineItem = { ...lineItem, ...competitionRefund.value[key] }
        }
        lineItems.push(lineItem)
      }

      const options: RefundOptions = {
        idempotencyKey: uuidv4(),
        lineItems,
        creditType: 'refund',
        // If there is no "active subscription", don't try to cancel it.
        // The UI should also prevent such nonsense (e.g. only offer the 'cancel subscription' checkbox if it makes sense to do so).
        // Note we might have default-initialized this value to true, even if there is not an "active subscription".
        // It is an error to send "hey please cancel this subscription" if there is no active subscription.
        cancelSubscription: props.invoice.hasActiveSubscription
          ? cancelSubscription.value
          : false,
        reconciliationOnly: authZ_reconciliationOnly.value && !disableReconciliationOnly.value
          ? reconciliationOnly.value
          : false,
      }

      if (checkRefund.value) {
        options.creditType = 'out_of_band'
        if (checkNum.value) options.checkNum = checkNum.value
      }

      if (refundMsg.value) {
        options.refundMsg = refundMsg.value
      }
      return options
    }

    const submitRefund = async () : Promise<void> => {
      if (!refund.value) {
        return;
      }

      System.directCommit_setRefundProcessing(true)

      const options = getRefundDetails()
      const isRequestingCancelSubscription = !!options.cancelSubscription;

      const lastStatusDatePriorToThisRefund = props.invoice.lastStatusDate;

      try {
        await axiosInstance.post(
          `v1/invoice/${props.invoice.instanceID}/refund`,
          options
        )

        if ((await pollForAsyncStripeWebhooksCompleted(props.invoice.instanceID)).ok) {
          iziToast.success({message: "Refund processed."})
        }
        else {
          // We might consider just polling forever?
          // And the user would go "oh somethings wrong" and then maybe we'd hear about it in a bug report.
          // One form of this is we had a bug where the server said "ok, I acknowledge your request and will do it!" but then didn't
          // actually do it, so we'd sit here spinning for a webhook that will never fire.
          iziToast.warning({message: "The server accepted the request but is taking a long time to process it. Check back in a few minutes to see updates reflected once completed."})
        }

        CheckoutStore.setProcessed({
          invoiceID: props.invoice.instanceID.toString(),
          processed: true,
        })

        emit("completedRefund")
        emit('processed', true)

        // reset misc. state as appropriate
        cancelSubscription.value = false;
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
      finally {
        System.directCommit_setRefundProcessing(false)
      }

      /**
       * There are 2 events that will potentially run async on the backend that we need to wait on:
       *  - stripe refund webhook
       *  - stripe subscription cancellation webhook (actually this will currently run as part of the refund webhook but that's an implementation detail)
       * If the events on the backend have run synchronously this will complete sucesfully after its first iteration.
       * Otherwise we need to poll to ask "are we there yet?"
       */
      async function pollForAsyncStripeWebhooksCompleted(invoiceInstanceID: Integerlike) : Promise<{ok: boolean}> {
        const ax = freshNoToastLoggedInAxiosInstance()
        for (let i = 0; i < 10; i++) {
          CheckoutStore.getInvoice({invoiceID: invoiceInstanceID.toString(), expand: true, ax});
          const invoice = requireNonNull(CheckoutStore.value.invoice[invoiceInstanceID])
          const lastStatusDateChanged = invoice.lastStatusDate !== lastStatusDatePriorToThisRefund
          const subscriptionCancellationCompletedIfRequired = isRequestingCancelSubscription
            ? !invoice.hasActiveSubscription
            : true;
          const continuePolling = !lastStatusDateChanged || !subscriptionCancellationCompletedIfRequired;

          if (continuePolling) {
            await new Promise(resolve => setTimeout(resolve, 3000));
          }
          else {
            return {ok: true};
          }
        }
        return {ok: false}
      }
    }

    /**
     * Flip the "refund this item" checkbox bit for some line item.
     * Recomputes the "is every line item checked?" value.
     */
    const toggleRefundItem = (lineItemID: string) => {
      refundItems.value[lineItemID] = !refundItems.value[lineItemID]
      for (const lineItem of props.invoice.lineItems) {
        if (!refundItems.value[lineItem.lineItemID]) {
          refundAll.value = false
          return;
        }
      }
      // if there are watchers on this, this assignment might become circular.
      refundAll.value = true;
    }

    /**
     * Mark or unmark line items as "yes, please refund this"
     */
    const setRefundItems = () => {
      for (const lineItem of props.invoice.lineItems) {
        refundItems.value[lineItem.lineItemID] = refundAll.value && lineItemIsConsideredRefundableByDefault(lineItem)
      }
    }

    /**
     * Flip the "refundAll" bit.
     * Two outcomes:
     *  - set to refundAll=false, and mark every line item as "no, does not need to be refunded"
     *  - set refundAll=true, and mark every line item as "yes, refund this"
     */
    const toggleRefund = () => {
      refundAll.value = !refundAll.value
      setRefundItems()
    }

    const ready = ref(false);

    onMounted(async () => {
      mungedRefundHoldback.value = await scanLineItemsForMungedRefundHoldback(axiosInstance, props.invoice.lineItems);

      initState()

      if (props.invoice.couponCode) {
        couponCode.value = props.invoice.couponCode
      }

      ready.value = true;
    })

    return {
      coupon,
      couponCode,
      refundAll,
      refundItems,
      refundActions,
      refundAmounts,
      cancelData,
      checkRefund,
      checkNum,
      refundMsg,
      route,
      toggleActionSelected,
      applyCoupon,
      getRefundDetails,
      submitRefund,
      toggleRefundItem,
      toggleRefund,
      showCouponInput,
      determineMaxRefund,
      mungedRefundHoldback,
      competitionRefund,
      ready,
      hasSomeLineItemWithBlockedPayment,
      dayjsFormatOr,
      lineItemSums,
      isSubscriptionInvoice,
      parseFloatOrFail,
      cancelSubscription,
      reconciliationOnly,
      authZ_reconciliationOnly,
      fk_registrarNotesValidation,
      disableReconciliationOnly,
    }
  },
})

/**
 * the refund holdback value on the CompetitionSeason object might be cfnull or otherwise parse as NaN, and should not be used directly
 * the `mungedRefundHoldback` is guaranteed to
 *   - hold valid value (it may be 0),
 *   - hold a string suitable for annotating a form input label (it may be the empty string)
 * Note that this is (usually? always?) merely a suggestion to the user and serves as a way to compute the initial suggested refund
 */
interface MungedRefundHoldback {
    value: number,
    // should be empty string for no message (when value is 0)
    maybeRefundLabelAnnotation: string
};

function defaultNilMungedRefundHoldback() : MungedRefundHoldback {
  return {
    value: 0,
    maybeRefundLabelAnnotation: ""
  }
}

/**
 * Scan a list of invoice line items for a CompetitionRegistration
 * If we find one (and we assume here that there will be exactly 0 or 1, with 1 being the more likely case)
 * pull the relevant CompetitionSeason object from api, and munge out the refund holdback
 * If we don't find a CompetitionRegistration, return a safe default value
 */
async function scanLineItemsForMungedRefundHoldback(axios: AxiosInstance, lineItems: LineItem[]) : Promise<MungedRefundHoldback> {
  for (const lineItem of lineItems) {
    if (lineItem.entity_type === "qCompetitionRegistration" && lineItem.entity) {
      const compReg = lineItem.entity
      const competitionSeason = await ilapi.getCompetitionSeason(axios, compReg.competitionUID, compReg.seasonUID);
      const maybeNaN_defaultHoldback = parseFloat(competitionSeason.refundHoldback as any);
      const defaultHoldback = isNaN(maybeNaN_defaultHoldback) ? 0 : maybeNaN_defaultHoldback;
      return {
        value: defaultHoldback,
        maybeRefundLabelAnnotation: defaultHoldback > 0 ? `(Default Holdback: $${defaultHoldback.toFixed(2)})` : ""
      }
    }
  }

  return defaultNilMungedRefundHoldback();
}

</script>