import { faCcAmex, faCcDiscover, faCcMastercard, faCcVisa, IconDefinition } from "@fortawesome/free-brands-svg-icons";
import { faCreditCard } from "@fortawesome/free-solid-svg-icons";
import { faTimesCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { Reflike, vReqT } from "src/helpers/utils";
import { computed, defineComponent } from "vue";
import { IL_PaymentMethod } from "./Payments.io"

export const PaymentMethodListingTableCells = defineComponent({
  props: {
    paymentMethod: vReqT<IL_PaymentMethod>(),
    selectedPaymentMethodID: vReqT<Reflike<string>>(),
  },
  emits: {
    deletePaymentMethod: () => true,
    paymentMethodSelected: () => true,
  },
  setup(props, ctx) {
    const creditCardLabel = (name: string, icon: IconDefinition) => {
      return <p class="text-sm flex items-center">
        <FontAwesomeIcon icon={icon} class="mr-2 text-lg"/>
        {name}
      </p>
    }

    const isBankAccountRequiringVerifyWithMicroDeposists = computed(() => {
      return props.paymentMethod.setupIntent?.next_action?.type === "verify_with_microdeposits"
    })

    const disabled = computed(() => {
      return isBankAccountRequiringVerifyWithMicroDeposists.value
    })

    const elemID = `il-pm-${props.paymentMethod.id}`
    return () => {
      return <>
        <td
          class="px-2 py-4 whitespace-nowrap text-sm leading-5 font-medium text-gray-900 flex justify-center items-center md:px-6">
          <t-singleRadio
            class="w-4"
            disabled={disabled.value}
            value={props.paymentMethod.id}
            modelValue={props.selectedPaymentMethodID.value}
            onChange={() => ctx.emit("paymentMethodSelected")}
            onInput={() => ctx.emit("paymentMethodSelected")}
            data-test={props.paymentMethod.id}
            id={elemID}
          />
        </td>
        <td class="px-2 py-4 whitespace-nowrap leading-5 text-lg text-gray-500 md:px-6">
          <label for={elemID}>
            {props.paymentMethod.card
              ? (
                props.paymentMethod.card.brand === "visa" ? creditCardLabel("Visa", faCcVisa)
                : props.paymentMethod.card.brand === "discover" ? creditCardLabel("Discover", faCcDiscover)
                : props.paymentMethod.card.brand === "mastercard" ? creditCardLabel("Mastercard", faCcMastercard)
                : props.paymentMethod.card.brand === "amex" ? creditCardLabel("Amex", faCcAmex)
                : creditCardLabel("Card", faCreditCard)
              )
              : props.paymentMethod.us_bank_account ? (
                <div>
                  <div>{props.paymentMethod.us_bank_account.bank_name}</div>
                  {props.paymentMethod.setupIntent?.next_action?.type === "verify_with_microdeposits"
                    ? <div class="text-xs">
                      <div>Pending account verification</div>
                      <div>Payment method cannot be used until it is verified</div>
                      {props.paymentMethod.setupIntent.next_action.verify_with_microdeposits?.hosted_verification_url
                        ? <div>
                          <a
                            class="il-link" target="_blank"
                            href={props.paymentMethod.setupIntent.next_action.verify_with_microdeposits?.hosted_verification_url}
                          >
                            Verify
                          </a>
                        </div>
                        : null
                      }
                    </div>
                    : null
                  }
                </div>
              )
              : props.paymentMethod.type // fallback for unhandled type
            }
          </label>
        </td>
        <td class="px-2 py-4 whitespace-nowrap text-sm leading-5 text-gray-500 md:px-6">
          {props.paymentMethod.card?.last4 || props.paymentMethod.us_bank_account?.last4 || ""}
        </td>
        <td class="px-2 py-4 whitespace-nowrap text-left text-sm leading-5 font-medium md:px-6">
          {props.paymentMethod.card
            ? `${props.paymentMethod.card.exp_month}/${props.paymentMethod.card.exp_year}`
            : ""
          }
        </td>
        <td class={["px-6 py-4 whitespace-nowrap text-left text-sm leading-5 md:px-14 font-medium text-red-600 cursor-pointer"]}
          onClick={() => ctx.emit("deletePaymentMethod")}
        >
          <FontAwesomeIcon icon={faTimesCircle}/>
        </td>
      </>
    }
  }
})
