import { boot } from 'quasar/wrappers'
import axios, { type AxiosRequestConfig, type AxiosResponse, type AxiosError, type AxiosInstance, type AxiosRequestHeaders, type InternalAxiosRequestConfig, CanceledError } from 'axios'
import mitt from 'mitt'
import { AxiosErrorWrapper } from "./AxiosErrorWrapper"
export { AxiosErrorWrapper } from "./AxiosErrorWrapper"
import type { IziToast } from 'izitoast'

import { updateApiUrl, axiosAuthBackgroundInstance, axiosBackgroundInstance, axiosInstance, axiosNoAuthInstance, getGlobalApiURL, DEFAULT_AXIOS_TIMEOUT_MS, X_TOKEN_RENEWAL } from "./AxiosInstances"
import { isAxiosErrorLike } from 'src/composables/InleagueApiV1'
import { System } from 'src/store/System'
import { User } from 'src/store/User'

const emitter = mitt()

export const FALLBACK_ERROR_MESSAGE = 'Sorry, something went wrong! An automatic notification has been dispatched to the developers. If any further action or information is required from you, inLeague Support will contact you within one business day. Otherwise, please try again later. We apologize for the inconvenience.'

const API_URL : string = (() => {
  if (process.env.NODE_ENV === "development" && process.env.MODE === "capacitor") {
    //
    // capacitor development mode, we're almost certainly running inside a device emulator, that won't resolve "inleague.localtest.me" correctly
    // `process.env.APP_URL` should be configured by quasar to the host machine's IP, along with the dev server port (i.e. 'https://<host-machine-ip>:<port>')
    // <host-machine-ip> appears configured by capacitor.config.json's server.URL property, and the port is configured by quasar.config.js's devServer.port property
    //
    // However, we cannot use <host-machine-ip>:<port>, since there is some login code that is allowed to effectively later rewrite API_URL, and in general
    // the application assumes that, for dev purposes, inleague.localtest.me is reachable; but on an emulated phone, dns resolves localtest.me to the emulated device, not the host machine
    //
    // So for (dev && capacitor) mode, we have to hit demo.inleague.io
    //
    return "https://demo.inleague.io/api";
  } else if ((process.env.NODE_ENV === "development" || process.env.LOCAL_STAGING) && /.+\.localtest\.me$/i.test(new URL(window.origin).hostname)) {
    //
    // n.b. window.* is not available in a Node context, and there are a few cases where this file is transitively imported from playwright tests.
    // But, in the "running inside playwright" case, process.env.NODE_ENV shouldn't match the conditions we test for prior to the pattern test against window.origin,
    // so reading from window.origin should always be OK.
    // fixme: don't transitively import this file from within playwright tests (seems to be done from importing /composables/InleagueApiV1 in a test),
    // AxiosErrorWrapper should probably be in a seperate file, that would solve the above.
    //

    // frontend app served on <portnum> from quasar/vite, but server api is on 443 from docker->nginx->lucee
    // Both of the following hostnames run the same application code, but:
    // -- https://testinleague.localtest.me hits the local machine test db
    // -- https://inleague.localtest.me hits the local machine non-test db
    return `https://${new URL(window.origin).hostname}/api/`;
  }
  else if(process.env.INLEAGUE_URL === 'https://api.inleague.io' && (window.location.origin === 'capacitor://localhost' ||  window.location.origin === 'http://localhost')) {
    // this arm represents "production android/iOS mode", we are a capacitor app on a user's realworld device
    // todo: docs indicating what window.location.origin should be in production mode? can we just use process.env.MODE === "capacitor" ?
    return 'https://api.inleague.io'
  } else if (process.env.INLEAGUE_URL !== 'https://api.inleague.io') {
    // not production mode, (probably?) not capacitor mode, fallback to whatever's loaded from .env
    return process.env.INLEAGUE_URL  + '/api'
  } else {
    // catch-all
    // todo: is this expected to be hit often? can it just be an error that indicates env isn't configured properly?
    return window.location.origin + '/api'
  }
})();

updateApiUrl(API_URL)

export default boot(({ app, store }) => {
  axiosNoAuthInstance.defaults.withCredentials = true
  axiosBackgroundInstance.defaults.withCredentials = true
  axiosAuthBackgroundInstance.defaults.withCredentials = true
  axiosInstance.defaults.withCredentials = true

  // the callbacks of any pending requests to be retried with new Authorization header:
  let subscribers: Function[] = []

  function addSubscriber(callback: Function) {
    subscribers.push(callback)
  }

  function onAccessTokenFetched(token: string) {
    subscribers = subscribers.filter(callback => callback(token))
  }

  axiosNoAuthInstance.interceptors.request.use(
    function (config) {
      return config
    },
    function (error) {
      System.directCommit_setMultipleCalls(false)
      System.directCommit_setLoading(false)
      return Promise.reject(error)
    }
  )

  axiosNoAuthInstance.interceptors.response.use(
    response => {
      System.directCommit_setLoading(false)
      return response
    },
    error => {
      System.directCommit_setMultipleCalls(false)
      System.directCommit_setLoading(false)
      return Promise.reject(new AxiosErrorWrapper(error))
    }
  )
  axiosAuthBackgroundInstance.interceptors.request.use(
    function (config) {
      const jwtString = User.jwtToken
      if (jwtString) {
        config.headers.Authorization = 'Bearer ' + jwtString
      }
      return config
    },
    function (error) {
      return Promise.reject(error)
    }
  )
  axiosAuthBackgroundInstance.interceptors.response.use(
    refreshJwtTokenResponseInterceptor,
    async error => {
      //
      // Terrible no good kludge -- discard this error if there was a failure __when trying to log a failure__
      // We discard the error in exactly the single condition that the response URL is the error logging url, otherwise it is true.
      // There should be a "axiosAuthBackgroundDiscardError" instance, or a way to conditionally disable/configure the error handling at call site, or something.
      //
      const discardError = (error.request instanceof XMLHttpRequest) && /v1\/oops/.test(error.request.responseURL);
      if (discardError) {
        // well, still report the error;
        // but we don't perform any of the subsequent toast or auth handlers or etc.
        return Promise.reject(error);
      }

      const { config, response } = error

      if (typeof response !== "object" || response === null) {
        // if the response is NOT some non-null object, there's nothing we can do with it
        app.config.globalProperties.$toast.error({
          message: FALLBACK_ERROR_MESSAGE
        })
      }
      else {
        if (response && response.status === 401) {
          // only want to display the error if the user was previously logged in at this window
          if (!User.value.loggedOutErrorDisplayed) {
            User.directCommit_setLoggedOutErrorDisplayed(true)
          }

          await logoutUserRetainingOnLoginRedirectURL();

          emitter.emit('loggedInToken', (token: string) => {
            onAccessTokenFetched(token)
          })

          const retryOriginalRequest = addSubscriber(async (token: string) => {
            if (token) {
              config.headers.Authorization = 'Bearer ' + token
            }
            await axiosInstance(config)
          })
          return retryOriginalRequest
        } else if (response && response.status >= 500) {
          // 500's are just "sorry, something went wrong"
          app.config.globalProperties.$toast.error({
            message: FALLBACK_ERROR_MESSAGE
          })
        } else if (response && response.status >= 300 && response.status < 400){
          // 300's are discarded
        } else {
          // maybe the error is an inleague response with a data.messages property, which
          // is an array of strings to display to the user.
          if (Array.isArray(response.data?.messages)) {
            app.config.globalProperties.$toast.error({
              message: response.data.messages.join(', '),
            })
          }
          // otherwise we don't know what it is
          else {
            app.config.globalProperties.$toast.error({
              message: FALLBACK_ERROR_MESSAGE
            })
          }
        }
      }

      // re-reject
      return Promise.reject(new AxiosErrorWrapper(error))
    }
  )

  axiosInstance.interceptors.request.use(
    function (config) {
      if (
        !System.value.paymentProcessing &&
        !System.value.refundProcessing
      ) {
        System.directCommit_setLoading(true)
      }
      const jwtString = User.jwtToken
      if (jwtString) {
        config.headers.Authorization= 'Bearer ' + jwtString
      }
      return config
    },
    function (error) {
      System.directCommit_setMultipleCalls(false)
      System.directCommit_setLoading(false)
      return Promise.reject(error)
    }
  )

  axiosInstance.interceptors.response.use(refreshJwtTokenResponseInterceptor)
  axiosInstance.interceptors.response.use(
    response => {
      System.directCommit_setLoading(false)
      return response;
    },
    async error => {
      System.directCommit_setMultipleCalls(false)
      System.directCommit_setLoading(false)
      const { config, response } = error
      if (response && response.status === 401) {
        // only want to display the error if the user was previously logged in at this window
        if (!User.value.loggedOutErrorDisplayed) {
          User.directCommit_setLoggedOutErrorDisplayed(true)
        }

        await logoutUserRetainingOnLoginRedirectURL();

        emitter.emit('loggedInToken', (token: string) => {
          onAccessTokenFetched(token)
        })

        // todo: does this do anything at all?
        // the intent seems to be to retry the request, once the token is resolved,
        // do we know that is always a legitimate or useful thing to do? the original caller certianly isn't sitting around awaiting such a thing
        const retryOriginalRequest : /*note that this checks*/ void = addSubscriber(async (token: string) => {
          if (token) {
            config.headers.Authorization = 'Bearer ' + token
          }
          await axiosInstance(config) // side effects here, or is this entirely a no-op?
        })

        return retryOriginalRequest // why return this, it's undefined. does this mean "no error" ?
      } else if (response && response.status >= 500) {
        app.config.globalProperties.$toast.error({
          message: FALLBACK_ERROR_MESSAGE,
        })
      } else if (response && response.status >= 300 && response.status < 400){
        // no special handling for 300 codes
      } else if (response && Array.isArray(response.data?.messages)) {
        app.config.globalProperties.$toast.error({
          message: response.data.messages.join(', '),
        })
      }
      else {
        app.config.globalProperties.$toast.error({
          message: FALLBACK_ERROR_MESSAGE,
        })
      }
      return Promise.reject(new AxiosErrorWrapper(error))
    }
  )
})

// these will be wrong in cases where multiple requests are in flight concurrently
export const defaultSetGlobalSingletonLoadingStateFlagInterceptors = {
  request: async (requestConfig: any) => {
    System.setLoading(true);
    return requestConfig;
  },
  responseOK: async (responselike: any) => {
    System.setLoading(false);
    return responselike;
  },
  responseError: async (responselike: any) => {
    System.setLoading(false);
    throw new AxiosErrorWrapper(responselike);
  }
} as const;

/**
 * Some endpoints return file blobs OR default api error responses.
 * When we ask/configure Axios to parse a successful result as a blob, it means that the error case will also be left as a blob.
 * So in the error case, we need to de-blobify to JSON, to end up with the typical api response error shape.
 * This should be added to the head of the response error interceptors list for any fresh instance intended to be used
 * with an endpoint behaving in this way.
 */
export async function __error__mungeBlobErrorToJson(v: any) {
  if (axios.isAxiosError(v) && v.response?.data instanceof Blob && v.response.data.type === "application/json") {
    // browser case
    try {
      v.response.data = JSON.parse(await v.response.data.text())
    }
    catch {
      // couldn't parse it? nothing to do
    }
  }
  else if (axios.isAxiosError(v) && v.response?.data instanceof Uint8Array) {
    // nodejs case
    try {
      v.response.data = JSON.parse(v.response.data.toString())
    }
    catch {
      // couldn't parse it? nothing to do
    }
  }
  else {
    throw Error(`Didn't match against either of the expected browser or NodeJS error types`)
  }

  // rethrow to next handler in chain or bubble out
  throw v;
}

export function defaultLoggedInErrorResponseHandler(iziToast: IziToast) {
  return async (error:any) : Promise<void> => {
    System.directCommit_setMultipleCalls(false) // yeah? we can know from here that all in flight requests are done?
    System.directCommit_setLoading(false)
    const { config, response } = error // hm we assume error has some specific type, is this an axios type?

    if (error instanceof CanceledError) {
      throw error;
    }

    if (response && response.status === 401) {
      // only want to display the error if the user was previously logged in at this window
      if (!User.value.loggedOutErrorDisplayed) {
        User.directCommit_setLoggedOutErrorDisplayed(true)
      }
      await logoutUserRetainingOnLoginRedirectURL();
    } else if (response && response.status >= 500) {
      iziToast.error({
        message: FALLBACK_ERROR_MESSAGE,
      })
    } else if (response && response.status >= 300 && response.status < 400){
      // no special handling for 300 codes
    } else {
      iziToast.error({
        message: response.data.messages.join(', '),
      })
    }
    throw new AxiosErrorWrapper(error);
  }
}

export function defaultDoHandlePossibleAxiosError(iziToast: IziToast, err: any) : void {
  // if it's not an Axios error to begin with, rethrow
  AxiosErrorWrapper.rethrowIfNotAxiosError(err)
  try {
    // default handling of axios error
    defaultLoggedInErrorResponseHandler(iziToast)(err)
  }
  catch (err) {
    // Default handling will have rethrown it.
    // If for whatever reason we did something that threw a non-axios error (unlikely, here), rethrow.
    // Otherwise, we're done, the axios error is to be discarded.
    AxiosErrorWrapper.rethrowIfNotAxiosError(err);
  }
}

export function defaultLoggedInErrorResponseHandler_noToastOn404(iziToast: IziToast) {
  return async (error: any) : Promise<void> => {
    {
      // yeah? we can know from here that __all__ in flight requests are done?
      System.setMultipleCalls(false)
      System.setLoading(false)
    }
    if (isAxiosErrorLike(error) && error.response.status === 404) {
      throw error;
    }
    else {
      await defaultLoggedInErrorResponseHandler(iziToast)(error);
    }
  }
}

/**
 * Logout the user, but do not clear the "on login go here" target, which
 * other code may have already set in response to some request's failure.
 *
 * related: MainLayout.vue
 */
async function logoutUserRetainingOnLoginRedirectURL() : Promise<void> {
  await User.logoutUser({clearRedirectOnLoginURL: false})
}

function refreshJwtTokenResponseInterceptor(response: AxiosResponse<any, any>) : AxiosResponse<any, any> {
  if (response.headers[X_TOKEN_RENEWAL]) {
    User.jwtToken = response.headers[X_TOKEN_RENEWAL]
  }
  return response
}

// we need to look into uncoupling the existing axios interceptor defintions from their dependencies on the boot closure,
// and make axios instance creation an ala carte affair (with the existing global axiosInstances kept, but redefined in ala carte terms)
export function freshAxiosInstance(args: {
  useCurrentBearerToken?: boolean,
  signal?: AbortSignal,
  requestInterceptors?: ((v: AxiosRequestConfig) => InternalAxiosRequestConfig | Promise<InternalAxiosRequestConfig>)[],
  responseInterceptors?: {
    ok?: (v: AxiosResponse) => AxiosResponse | Promise<AxiosResponse>,
    // arg to error handler could be anything, but is probably an AxiosError or AxiosErrorWraper
    // we'll type it as such.
    error?: (v: AxiosError | AxiosErrorWrapper) => any
  }[],
  timeout_ms?: number
}) : AxiosInstance {
  const headers : Record<string, string> = {};
  if (args.useCurrentBearerToken) {
    const token = User.jwtToken;
    headers["Authorization"] = `Bearer ${token}`
  }

  const fresh = axios.create({
    baseURL: getGlobalApiURL(),
    timeout: args.timeout_ms ?? DEFAULT_AXIOS_TIMEOUT_MS,
    headers,
    signal: args.signal,
  });

  for (const interceptor of args.requestInterceptors ?? []) {
    fresh.interceptors.request.use(interceptor);
  }

  if (args.useCurrentBearerToken) {
    fresh.interceptors.response.use(refreshJwtTokenResponseInterceptor);
  }

  for (const interceptor of args.responseInterceptors ?? []) {
    fresh.interceptors.response.use(interceptor.ok, interceptor.error);
  }

  return fresh;
}

/**
 * No toasts on success or failure, no interaction with the global loading spinner.
 * Uses the current bearer token for the current logged in user.
 * If there is no current logged user, using this is not correct.
 */
export function freshNoToastLoggedInAxiosInstance() {
  return freshAxiosInstance({useCurrentBearerToken: true, requestInterceptors: [], responseInterceptors: []})
}

export {
  axios,
  axiosNoAuthInstance,
  axiosBackgroundInstance,
  axiosAuthBackgroundInstance,
  axiosInstance,
  updateApiUrl,
}
