<template lang="pug">
div(v-if="ready" style="--fk-bg-input:white;")
  div(data-what="sub-modals-container")
    //- Currently, all child routes are modals. Makes testing easier (can page.goto right to it), and supports deep linking,
    //- but deep linking to modals is maybe not a very useful thing? Could maybe be cool to offer URLs
    //- to modals that perform some action, in emails to administrators. There are some problems with
    //- scroll behavior for this, see router's `scrollBehavior` definition.
    //-
    //- One bad thing with this approach is that the modal's "onleave" transitions don't play, because we just get hard unmounted on route change,
    //- so we have to handle that in the child route components (don't nav-away until the animations are done).
    //-
    router-view

  div.mt-12
    h1.mt-12.text-center Referee Scheduler
    //-
    //- header group with filter options and "get the things" button
    //-
    HasInvalidRiskStatusBlurb(v-if="!hasValidRiskStatus" class="my-4" data-test="hasInvalidRiskStatusBlurb")
    div(v-else class="max-w-7xl m-auto" style="--fk-max-width-input: none;")
      div(class="grid grid-cols-1 lg:grid-cols-2 xl:grid-cols-4")
        //-
        //- cell
        //-
        div(class="m-2" style="--fk-margin-outer: 0;")
          div(class="font-medium text-sm mb-1") Program
          FormKit.mr-12(
            v-model='selectedCompetitionUID',
            :options='competitionSelectOptions',
            type='select',
            :placeholder='competitionSelectOptions.length === 0 ? "No available programs" : "Select a program"',
            :disabled='competitionSelectOptions.length === 0',
            data-test='competitions',
          )

        //-
        //- cell
        //-
        //- max-width constrained to same as surrounding <FormKit> elements
        //-
        div(class="m-2 relative flex flex-col")
          div(class="font-medium text-sm mb-1 flex items-center justify-between")
            div Division
          ScopedBool
            template(v-slot="{toggle, setFalse: close, value: isVisible}")
              div(class="flex items-center flex-grow")
                div
                  span(
                      @click="() => toggle()"
                      :class="`text-blue-700 cursor-pointer underline bg-white ${isVisible ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`"
                      style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                    )
                      font-awesome-icon(style="outline: none;" :icon='["fas", "list-check"]' v-tooltip="{content: 'Select multiple'}")
                div(class="ml-1") {{ selectedDivisionsDescriptor }}
              ScopedWindowSize
                template(v-slot="{innerWidth}")
                  template(v-if="0 <= innerWidth && innerWidth < 640")
                    Modal(:isOpen="isVisible" @close="() => close()")
                      template(v-slot:title)
                        div Select divisions
                        div(class="border-b border-slate-200")
                      template(v-slot:content)
                        div(class="overflow-y-auto" style="max-height:80vh")
                          SelectMany(v-bind="selectManyDivsViewModel.props" v-on="selectManyDivsViewModel.emits")
                  template(v-if="640 <= innerWidth && innerWidth < Infinity")
                    Transition(
                      enter-active-class = "transition duration-100 ease-out"
                      enter-from-class = "translate-y-1 opacity-0"
                      enter-to-class = "translate-y-0 opacity-100"
                      leave-active-class = "transition duration-100 ease-in"
                      leave-from-class = "translate-y-0 opacity-100"
                      leave-to-class = "translate-y-1 opacity-0"
                    )
                      template(v-if="isVisible")
                        //- tiny offset of -1px and min-width > 100% is to make it "just slightly" extend past its container boundaries,
                        //- to prevent cases where a border on an element above or below is 1px larger than the popover, which looks bad
                        div(
                          v-onClickOutside="() => { close() }"
                          class="absolute bg-white overflow-auto z-10 top-full rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-2 max-h-96"
                          style="left:-1px; min-width:102%"
                        )
                          SelectMany(v-bind="selectManyDivsViewModel.props" v-on="selectManyDivsViewModel.emits")

          div(v-if="!selectedCompetitionUID" class="absolute top-0 left-0 w-full h-full flex items-center justify-center" style="background-color: rgba(255,255,255,.95)")
            | Select a program

        //-
        //- cell
        //-
        div(class="m-2" style="--fk-margin-outer: 0;")
          div(class="font-medium text-sm mb-1") Week
          FormKit.ml-12(
            v-model='selectedWeek',
            :options='weekSelectOptions',
            type='select',
            placeholder='Select a Week',
            data-test='weeks',
          )

        //-
        //- cell
        //-
        div(class="m-2" style="--fk-margin-outer: 0;")
          div(class="font-medium text-sm mb-1") Field
          FormKit.ml-12(
            v-model='selectedField',
            :options='fieldSelectOptions',
            type='select',
            placeholder='All Fields',
            :disabled='!selectedWeek',
            data-cy='fields',
          )

        //-
        //- cell
        //-
        div(class="m-2 flex items-center col-span-1 lg:col-span-2 xl:col-span-4")
          div
            div(class="text-sm font-medium mb-1") Assignment types
            div(class="relative")
              ScopedBool
                template(v-slot="{toggle, setFalse: close, value: isVisible}")
                  div(class="flex items-center flex-grow")
                    div
                      span(
                          @click="() => toggle()"
                          :class="`text-blue-700 cursor-pointer underline bg-white ${isVisible ? 'bg-slate-200' : 'hover:bg-slate-100 active:bg-slate-200'}`"
                          style="border-radius:50%; padding:.5em; margin-left:-.5em;"
                        )
                          font-awesome-icon(style="outline: none;" :icon='["fas", "list-check"]' v-tooltip="{content: 'Select multiple'}")
                    div(class="ml-1") {{ refSlotOptionsForSelectedCompetition ? `${refSlotOptionsForSelectedCompetition.filter(v => v.selected).length} selected` : "" }}
                  ScopedWindowSize
                    template(v-slot="{innerWidth}")
                      template(v-if="0 <= innerWidth && innerWidth < 640")
                        Modal(:isOpen="isVisible" @close="() => close()")
                          template(v-slot:title)
                            div Select ref slots
                            div(class="border-b border-slate-200")
                          template(v-slot:content)
                            div(class="overflow-y-auto" style="max-height:80vh")
                              SelectMany(v-bind="selectManyRefSlotsViewModel.props" v-on="selectManyRefSlotsViewModel.emits")
                      template(v-if="640 <= innerWidth && innerWidth < Infinity")
                        Transition(
                          enter-active-class = "transition duration-100 ease-out"
                          enter-from-class = "translate-y-1 opacity-0"
                          enter-to-class = "translate-y-0 opacity-100"
                          leave-active-class = "transition duration-100 ease-in"
                          leave-from-class = "translate-y-0 opacity-100"
                          leave-to-class = "translate-y-1 opacity-0"
                        )
                          template(v-if="isVisible")
                            //- tiny offset of -1px and min-width > 100% is to make it "just slightly" extend past its container boundaries,
                            //- to prevent cases where a border on an element above or below is 1px larger than the popover, which looks bad
                            div(
                              v-onClickOutside="() => { close() }"
                              class="absolute bg-white overflow-auto z-10 top-full rounded-lg shadow-lg ring-1 ring-black ring-opacity-5 p-2 max-h-96"
                              style="left:-1px; min-width:16em"
                            )
                              SelectMany(v-bind="selectManyRefSlotsViewModel.props" v-on="selectManyRefSlotsViewModel.emits")
          div(class="ml-auto")
            t-btn.self-center.text-sm.font-medium(
              :margin="false"
              type='button'
              color="green"
              @click='() => getCurrentSchedule()',
              data-test='view-schedule'
            )
              div View Schedule

      template(
        v-if='isAdmin',
        data-cy='adminToggle'
      )
        div(class="text-sm grid grid-cols-2 gap-2 w-full justify-center lg:max-w-[66%] m-auto" style="--fk-margin-outer:none;")
          div(class="flex flex-col rounded-md border border-slate-200 p-2 transition bg-white")
            div(class="inline-block")
              label(class="mb-2 inline-flex items-center")
                input(type="radio" v-model="viewAdmin" :value="true" name="viewAdminRadio" class="transition")
                span(class="ml-2") Admin scheduler
            div
              FormKit(
                :key="`shouldSendEmailToRefOnApprovalsAndCancellations/${viewAdmin}/${shouldSendEmailToRefOnApprovalsAndCancellations}`"
                type="checkbox"
                :value="viewAdmin && shouldSendEmailToRefOnApprovalsAndCancellations"
                @input="() => { shouldSendEmailToRefOnApprovalsAndCancellations = !shouldSendEmailToRefOnApprovalsAndCancellations }"
                label="Send email to each ref on each approval, bulk approval ('approve all pending assignments'), or removal of an assignment"
                :disabled="!viewAdmin"
              )
          div(class="flex flex-col rounded-md border border-slate-200 p-2 transition bg-white")
            div(class="inline-block")
              label(class="inline-flex items-center")
                input(type="radio" v-model="viewAdmin" :value="false" name="viewAdminRadio" class="transition")
                span(class="ml-2") Self scheduler
          div(
            v-if="publishedSchedules.underlying.status !== 'idle'"
            class="w-full rounded-md border border-slate-200 p-2 bg-white"
            style="grid-column: 1/3;"
          )
            PublishedSchedulesDisplay(:p_publishedSchedules="publishedSchedules")

  div(v-if="hasValidRiskStatus")
    .mx-auto.flex.flex-col.align-center(v-if='Object.keys(groupedGames.groups).length')
      .quasar-style-wrap.mt-4(data-cy='allGamesTable')
        .row.justify-evenly.flex-none.mt-4(v-if="isAdmin && viewAdmin")
          div.row.flex.items-center
            Btn2(
              class="mt-8 px-2 py-2"
              @click='approvePendingRefs'
              data-test="approveAllPending"
            )
              div Approve All Pending Assignments
              div(v-if="shouldSendEmailToRefOnApprovalsAndCancellations" class="text-xs")
                | with confirmation emails
            q-table.m-2.tableWidth(
              :rows-per-page-options='[0]',
              hide-pagination,
              dense,
              v-if='viewAdmin && isAdmin',
              :rows='keys',
              :columns='keyColumns',
              data-cy='keyTable'
            )
              template(v-slot:body-cell-key='props')
                q-td
                  .text-red-7 {{ props.row.key }}

      div(class="shadow-md rounded-md my-2")
        div(class="p-2 bg-gray-200 rounded-t-md flex items-center")
          div Sort
          div(class="text-xs ml-auto" v-if="isInleague")
            div(class="il-link" @click="resetSort") (il=1) use default
        div(class="p-2")
          div(style="display:grid; grid-template-columns: max-content min-content min-content; grid-gap: .5em;")
            div Group By
            select(style="all: revert; padding:.25em;" v-model="sortConfig.topLevelGroupBy")
              option(value="byDateTime") Game Date
              option(value="byDiv") Division
            select(style="all: revert; padding:.25em;" v-model="sortConfig.topLevelGroupSort")
              option(value="asc") Asc
              option(value="desc") Desc

            div For each group, sort by
            select(style="all: revert; padding:.25em;" v-model="sortConfig.perGameColumn")
              option(v-for="option in sortableColumnOptions" :value="option.value") {{ option.label }}
            select(style="all: revert; padding:.25em;" v-model="sortConfig.perGameDir")
              option(value="asc") Asc
              option(value="desc") Desc
      //-
      //- v-for iterable here is (v,k) <=> (augmentedGames: AugmentedGames[], groupKey: string)
      //-
      RefereeScheduleTable(
        v-for='(xpackage, groupKey) in groupedGames.groups',
        :key="groupKey"

        v-on="refereeScheduleTableHandlers"

        :refColumns='xpackage.refColumns',
        :augmentedGames="xpackage.games"
        :showRefPosNamesPerButton="xpackage.showRefPosNamesPerButton"
        :isMultiDivSelection="selectedDivIDs.length > 1"
        :tableTitle="xpackage.tableTitle"
        :groupingMode="sortConfig.topLevelGroupBy"

        :teams='teams',
        :isAdmin='isAdmin && viewAdmin',
        :isAdminView='viewAdmin'
        :isSelfScheduler="!viewAdmin"

        :targetCompetitionUID="selectedCompetitionUID"
        :comp_cancellationPreGameDeadlineHours="selectedCompetition_refCancelHours"
        :comp_nonAdminsCanCancelTheirOwnConfirmedAssignments="selectedCompetition_refCanCancel"
        :selectedField="selectedField"
        :refSlotOptionsForSelectedCompetition="refSlotOptionsForSelectedCompetition"
        :willSendApprovalAndCancellationEmails="shouldSendEmailToRefOnApprovalsAndCancellations"
        :sortConfig="sortConfig"
      )
    .text-center.mt-4.italic(v-else-if='searchComplete')
      | Sorry, there are no games with volunteer positions available for you meeting this criteria.
</template>

<script lang="tsx">
import { defineComponent, ref, Ref, onMounted, watch, computed, reactive } from 'vue'

import {
  formatDateWithDashes,
  addDays,
} from 'src/helpers/formatDate'
import { AxiosErrorWrapper, axiosInstance, freshNoToastLoggedInAxiosInstance } from 'boot/axios'
import RefereeScheduleTable from 'src/components/RefereeSchedule/RefereeScheduleTable.vue'
import { Competition, Guid, DivisionID, Datelike } from 'src/interfaces/InleagueApiV1'
import {
  RefSlotConfig,
  TeamI,
  WellKnownRefSlotIndex,
} from 'src/interfaces/Store/client'
import { Game, GetPublicationDatesResponse } from 'src/composables/InleagueApiV1.Game'
import {
  QTableBodyCellRefereeVSlotBindingName, QTableRefereeColumnDef, AugmentedGame, ALL_FIELDS_FIELDID, RefSlotOption,
  Emits as RefereeScheduleTableEmits,
  ApproveRefAssignmentEvent,
  CancelRefAssignmentEvent,
  CancelRefSignupRequestEvent,
  CreateRefSignupRequestEvent,
  colNames,
  SortConfig,
  isGameColumn,
} from './RefereeScheduleTable.ilx'
import { createWeekOptionsForCompetitionSeason, chooseCurrentWeekFromAvailableWeeks, getRefSlotConfigDisjointnessInfo } from './R_RefereeSchedule.shared'
import authService from 'src/helpers/authService'
import { arrayFindIndexOrFail, assertTruthy, checkedObjectEntries, DeepConst, exhaustiveCaseGuard, forceCheckedIndexedAccess, identity, isGuid, isSortDir, parseFloatOr, parseIntOrFail, requireNonNull, sortBy, sortByDayJS, sortByMany, type UiOption } from 'src/helpers/utils'
import { RouterLink, useRoute } from 'vue-router'
import { ScopedBool, ScopedWindowSize } from './MiscUiUtils'
import { vueDirective_ilOnClickOutside } from "src/helpers/OnClickOutside"
import * as SelectMany from "./SelectMany"
import * as iltypes from "src/interfaces/InleagueApiV1"
import * as ilgame from "src/composables/InleagueApiV1.Game"
import { Modal } from "src/components/UserInterface/Modal"
import { GlobalInteractionBlockingRequestsInFlight } from 'src/store/EventuallyPinia'
import { User } from 'src/store/User'
import { getCompetitionsOrFail } from 'src/store/Competitions'
import { Client, RefSlotConfigLookup } from "src/store/Client"
import { ReactiveReifiedPromise } from 'src/helpers/ReifiedPromise'
import { PublishedSchedulesDisplay } from "src/components/RefereeSchedule/PublishedSchedulesDisplay"
import { AxiosInstance } from 'axios'
import { currentUserHasValidRiskStatus, currentUserIsReflikeUser } from './R_RefereeSchedule.route'
import * as R_FamilyProfile from "src/components/FamilyProfile/pages/FamilyProfile.ilx"
import dayjs from 'dayjs'
import { Btn2 } from '../UserInterface/Btn2'

/**
 * Thin wrapper around `localStorage`
 */
const localStorePersistence = {
  maybeGet(key: string) : string | null {
    return localStorage.getItem(key);
  },
  maybeGetGuid(key: string) : iltypes.Guid | null {
    const v = localStorage.getItem(key)
    return isGuid(v, "upper") ? v : null;
  },
  maybeGetGuidArray(key: string) : iltypes.Guid[] | null {
    try {
      const maybeArray = JSON.parse(localStorage.getItem(key) ?? "null")
      return Array.isArray(maybeArray) && maybeArray.every(_ => isGuid(_, "upper"))
        ? maybeArray
        : null
    }
    catch {
      return null;
    }
  },
  write(k: string, v: string) {
    localStorage.setItem(k, v)
  }
};

/**
 * Store for selected options, reads from localStorage for initial values on module load,
 * writesback changes to localStorage.
 */
const selectionsPersistence = (() => {
  const SELECTED_COMPETITION = "refScheduler/selections/competitionUID"
  const SELECTED_DIVIDS = "refScheduler/selections/divIDs"
  const SELECTED_FIELD = "refScheduler/selections/field"

  const selectedCompetitionUID = ref<iltypes.Guid | "">(localStorePersistence.maybeGetGuid(SELECTED_COMPETITION) ?? "");
  watch(selectedCompetitionUID, () => localStorePersistence.write(SELECTED_COMPETITION, selectedCompetitionUID.value))

  const selectedDivIDs = ref<iltypes.Guid[]>(localStorePersistence.maybeGetGuidArray(SELECTED_DIVIDS) ?? [])
  watch(selectedDivIDs, () => localStorePersistence.write(SELECTED_DIVIDS, JSON.stringify(selectedDivIDs.value)), {deep: true})

  const selectedField = ref<string>(localStorePersistence.maybeGet(SELECTED_FIELD) ?? "")
  watch(selectedField, () => localStorePersistence.write(SELECTED_FIELD, selectedField.value))

  return {
    selectedCompetitionUID,
    selectedDivIDs,
    selectedField,
  }
})()

const HasInvalidRiskStatusBlurb = defineComponent({
  setup() {
    const FamilyProfile = defineComponent(() => () => {
      const loc = R_FamilyProfile.asRouteLocationRaw({name: R_FamilyProfile.RouteName.default})
      return <RouterLink to={loc}>Family Profile</RouterLink>
    })

    return () => {
      return (
        <div>
          Access to the referee scheduler requires a current AYSO volunteer registration and background check.
          Please navigate to your <FamilyProfile class="il-link"/> and select 'Volunteer Now' if you have not yet completed your volunteer profile for the season.
          If you have, select 'Begin Background Check.' If your background check shows 'pending review,' please contact your referee administrator for assistance.
        </div>
      )
    }
  }
})

export default defineComponent({
  name: 'RefereeSchedulePage',
  components: {
    RefereeScheduleTable,
    SelectMany: SelectMany.SelectMany,
    Modal,
    ScopedBool,
    ScopedWindowSize,
    PublishedSchedulesDisplay,
    HasInvalidRiskStatusBlurb,
    Btn2,
  },
  directives: {
    onClickOutside: vueDirective_ilOnClickOutside
  },
  setup() {
    const route = useRoute();

    /**
     * map of competitionUIDs to list of divIDs -- clarify: what does this mean?
     */
    const refAuth = ref({}) as Ref<{[competitionUID: Guid]: /*no-unchecked-indexed-access*/ undefined | DivisionID[]}>
    /**
     * map of competitonUIDs to list of divIDs -- clarify: what does this mean?
     */
    const adminAuth = ref({}) as Ref<{[competitionUID: Guid]: /*no-unchecked-indexed-access*/ undefined | DivisionID[]}>

    /**
     * ref slot configs are initialized to a dummy default and then fully initialized during onMounted
     * to the actual lookup. After full initialization in onMounted, it is expected to remain valid throughout
     * the life of the component.
     */
    const refSlotConfigLookup = ref(RefSlotConfigLookup.defaultLookup())

    const {
      selectedCompetitionUID,
      selectedDivIDs,
      selectedField,
    } = selectionsPersistence

    const sortConfig = ref(sortConfig_fromLocalStorageOrDefaul(User.userData?.userID ?? null))
    watch(() => sortConfig.value, () => {
      sortConfig_writeToLocalStorage(User.userData?.userID ?? null, sortConfig.value);
    }, {deep: true})
    const resetSort = () => {
      sortConfig.value = sortConfig_freshDefault()
    }
    const sortableColumnOptions : UiOption[] = [
      {value: colNames.time, label: "Game time"},
      {value: colNames.field, label: "Field"},
      {value: colNames.gameDivision, label: "Division"},
      {value: colNames.gameNo, label: "Game num"},
    ];

    // selectedWeek generally wants to always be initialized to "current week", not a saved version of the last value from last session
    const selectedWeek = ref("")

    /**
     * can be null meaning "no such thing right now"
     */
    const refSlotOptionsForSelectedCompetition = ref<null | RefSlotOption[]>(null);

    const competitionSelectOptions = ref<{label: string, value: Guid}[]>([])
    /**
     * local cache of selectable competitions.
     * if the competitionUID is in the competitionSelectOptions array,
     * the associated competition __must__ be in this array
     */
    const allSelectableCompetitions = ref<readonly Competition[]>([])
    const selectedCompetition = computed(() => {
      return allSelectableCompetitions.value.find(v => v.competitionUID === selectedCompetitionUID.value)
    })
    const selectedCompetition_refCancelHours = computed(() => {
      return parseFloatOr(selectedCompetition.value?.refCancelHours, undefined);
    })
    const selectedCompetition_refCanCancel = computed(() => {
      return selectedCompetition.value?.refCanCancel ?? false;
    })

    // local cache, map of (divID -> uiName)
    const allDivisions = ref({}) as Ref<{ [divID: Guid]: /*division uiName*/ string }>
    const selectedDivisionsDescriptor = computed(() => {
      if (divisionSelectOptions.value.length === 0) {
        return "No options available"
      }

      if (selectedDivIDs.value.length > 0 && selectedDivIDs.value.length === divisionSelectOptions.value.length) {
        return "All"
      }

      if (selectedDivIDs.value.length === 1) {
        return allDivisions.value[selectedDivIDs.value[0]]
      }

      return `${selectedDivIDs.value.length} selected divisions`
    });

    const divisionSelectOptions = ref<UiOption[]>([])
    const fieldSelectOptions = ref<{label: string, value: string}[]>([])
    const weekSelectOptions = ref<{label: string, value: string}[]>([])

    const teams = ref([]) as Ref<DeepConst<TeamI[]>>

    const ready = ref(false)

    /**
     * On receipt of games from the API, we augment them to be coupled together with "the best" ref config we can find for
     * each game's (competition, division). This is the source of truth for games.
     */
    const __gamesAndComps = ref<{readonly games: Game[], readonly comps: Map<Guid, Competition>}>({games: [], comps: new Map()})
    /**
     * Games are grouped based on various criteria. This a value derived from __gamesAndComps.
     */
    const groupedGames = computed<{type: "byDateTime" | "byDiv", groups: {[key: string]: {
      games: AugmentedGame[],
      showRefPosNamesPerButton: boolean,
      tableTitle: string,
    }}}>(() => {
      if (sortConfig.value.topLevelGroupBy === "byDateTime") {
        const gamesByGameDate = buildGamesByGameDate(teams.value, __gamesAndComps.value.games)
        const augmentedGamesByGameDate = buildAugmentedGamesByGameDate(
          refSlotConfigLookup.value,
          gamesByGameDate,
          __gamesAndComps.value.comps,
          selectedDivNameForGroupByTimeView()
        );
        const v = sortGamesByGameDate(augmentedGamesByGameDate)

        return {
          type: "byDateTime",
          groups: v
        }
      }
      else if (sortConfig.value.topLevelGroupBy === "byDiv") {
        const gamesByDiv = buildGamesByDivID(teams.value, __gamesAndComps.value.games)
        const augmentedGamesByDiv = buildAugmentedGamesByDiv(refSlotConfigLookup.value, gamesByDiv, __gamesAndComps.value.comps)
        const v = sortGamesByDivGenderAndNum(augmentedGamesByDiv)

        return {
          type: "byDiv",
          groups: v
        }
      }
      else {
        exhaustiveCaseGuard(sortConfig.value.topLevelGroupBy)
      }

      function selectedDivNameForGroupByTimeView() {
        if (selectedDivIDs.value.length === 0) {
          // shouldn't happen except during time between setup-complete and onMounted call that does async setup
          // could probably be smarter about setting a ready flag, or setting this null, or etc.
          return ""
        }
        else if (selectedDivIDs.value.length === divisionSelectOptions.value.length) {
          return "All Divisions"
        }
        else if (selectedDivIDs.value.length === 1) {
          return allDivisions.value[selectedDivIDs.value[0]] ?? ""
        }
        else {
          return "Multiple Divisions"
        }
      }
    })

    /**
     * published schedules for the currently selected competitionUID and divIDs
     */
    const publishedSchedules = ReactiveReifiedPromise<GetPublicationDatesResponse>();
    const searchComplete = ref(false)

    const keys = [
      { key: 'Conflict', value: "Referee's child is playing" },
      { key: '(r)', value: 'Not current with AYSO' },
    ]
    const keyColumns = ref([
      {
        name: 'key',
        required: true,
        label: 'Key',
        field: (row: { key: string; value: string }) => row.key,
        sortable: false,
        align: 'left',
      },
      {
        name: 'value',
        required: true,
        label: 'Value',
        field: (row: { key: string; value: string }) => row.value,
        sortable: false,
        align: 'left',
      },
    ])

    /**
     * true="show admin mode"
     * false="show self-scheduler mode"
     *
     * There is possibly some dependency on `isAdmin` here, which is itself a function of (adminAuth, selectedWeek, selectedCompetition),
     * where viewAdmin would need to be force set to false if `isAdmin` changes based on some user selection.
     * Which is to say, there may be situations where we need to set viewAdmin=false in response to some user selection.
     *
     * On setup, if user is registrar, we will consider that they want to "view admin". This is not robust to subsequent reactive updates
     * to User.value.*, e.g. events such as impersonations or etc.
     *
     * CLARIFY: It's not entirely clear that "isRegistrar" implies `isAdmin` will definitely be computed to true. Do we expect that to be the case?
     */
    const viewAdmin = ref(authService(User.value.roles, "registrar"))
    const shouldSendEmailToRefOnApprovalsAndCancellations = ref(false);

    /**
     * Is the user considered an admin for our purposes here.
     *
     * TODO: there are some oddities here. The currently questionable behavior is documented in comments and should be addressed.
     * There's some smell, but "it's been working" for 2+ years, so it's not immediately clear this is changeable.
     */
    const isAdmin = computed(() => {
      if (Object.keys(adminAuth.value).length && selectedWeek.value) {
        // n.b. this says "adminAuth has at least one competitionUID in it, and the user has selected any week",
        // which may be a bit underconstrained
        return true
      } else if (
        // Here, owing to to the initial predicate that wasn't satisfied, `adminAuth` is either an empty object, or selectedWeek is falsy.
        // Secondarily, the `.includes(selectedWeek)` is nonsensical, because adminAuth[competition] has type `DivUID(GUID)[]`,
        // rather than `Datelike[]` (unfortunately, both are aliases for `string[]`, so both typecheck).
        // So, we expect this to never match, right?
        adminAuth.value[selectedCompetitionUID.value] &&
        adminAuth.value[selectedCompetitionUID.value]?.includes(selectedWeek.value)
      ) {
        return true
      }
      else {
        return false
      }
    })


    /**
     * filters displayable competitions based on user authz
     */
    const filterCompetitions = (comps: readonly Competition[]) => {
      const effectivelySuperUser = currentUserIsReflikeUser()
      const hasValidRiskStatus = currentUserHasValidRiskStatus()

      return comps.filter(comp => {
        if (!comp.hasRefScheduling) {
          return false
        }

        if (effectivelySuperUser || (!comp.limitRefAccess && hasValidRiskStatus)) {
          return refAuth.value[comp.competitionUID]?.length
            || adminAuth.value[comp.competitionUID]?.length
        }

        return false;
      })
    }

    /**
     * narrow divisions by selected competitionUID
     * INVESTIGATE: this should be dependent on selected competition?
     */
    const filterDivisions = (divisionNameByDivision: { [divID: Guid]: /* division uiName */ string }) => {
      const filtered = {} as { [divID: Guid]: /* division uiName */ string }
      for (const divID in divisionNameByDivision) {
        const refAuthMapping = refAuth.value[selectedCompetitionUID.value];
        const adminAuthMapping = adminAuth.value[selectedCompetitionUID.value];
        if (refAuthMapping?.includes(divID) || adminAuthMapping?.includes(divID)) {
          filtered[divID] = divisionNameByDivision[divID]
        }
      }
      return filtered
    }

    const getAdminOptions = async () : Promise<{[competitionUID: Guid]: DivisionID[]}> => {
      try {
        const response = await axiosInstance.get('v1/refereeAdminDivisions')
        return response.data.data
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }

      // Failure case, so we don't have any data.
      // This shouldn't happen, but not much we can do if it does.
      return {};
    }

    const getRefOptions = async () : Promise<{[competitionUID: Guid]: DivisionID[]}> => {
      try {
        const response = await axiosInstance.get('v1/refereeDivisions')
        return response.data.data
      } catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err);
      }

      // Failure case, so we don't have any data.
      // This shouldn't happen, but not much we can do if it does.
      return {};
    }

    const createWeekSelectOptions = async () : Promise<{value: string, label: string}[]> => {
      // selectedCompetitionUID must always be a valid competitionUID
      const comp = await Client.getCompetitionByUID(selectedCompetitionUID.value);
      if (!comp) {
        return [];
      }

      const season = await Client.getSeasonByUID(comp.seasonUID);

      //
      // We should always have a `currentCompetitionSeason` property.
      // If we don't have it here, it's a bug.
      //
      // Should we also always have a competition and season? Presumably yes.
      // The guards here primarily serve to narrow away falsy types, which, again,
      // we here assume never happen.
      //
      if (!season || !comp.currentCompetitionSeason) {
        return []
      }

      // remap week options from object to array
      const record = createWeekOptionsForCompetitionSeason(comp, comp.currentCompetitionSeason, 51);
      const result : {label: string, value: string}[] = [];
      for (const key of Object.keys(record)) {
        result.push({label: record[key], value: key});
      }

      return result;
    }

    const createCompetitionSelectOptions = async (competitions: readonly Competition[]) : Promise<UiOption[]> => {
      const filteredCompetitions = filterCompetitions(competitions)
      return filteredCompetitions.map(c => ({value: c.competitionUID, label: c.competition}));
    }

    const createDivisionSelectOptions = async (divisionNameByDivID: {[divID: Guid]: string}) => {
      const filteredDivisions = filterDivisions(divisionNameByDivID);

      const result : {label: string, value: string}[] = []
      for (const divID of Object.keys(filteredDivisions)) {
        result.push({
          label: filteredDivisions[divID],
          value: divID
        })
      }

      return result;
    }

    const createFieldSelectOptions = async () : Promise<UiOption[]> => {
      const result : UiOption[] = [{label: "All fields", value: ALL_FIELDS_FIELDID}]
      const allFields = await Client.loadFields()

      for (const field of allFields) {
        result.push({
          label: field.fieldAbbrev,
          value: field.fieldID.toString(),
        })
      }

      return result;
    }

    const initPrimaryComponentState = async () => {
      refAuth.value = await getRefOptions()
      adminAuth.value = await getAdminOptions()

      allSelectableCompetitions.value = (await getCompetitionsOrFail()).value;
      allDivisions.value = await (async () => {
        const divisions = await Client.loadDivisions();
        const result : {[divID: Guid]: string} = {}
        for (const division of divisions) {
          result[division.divID] = division.displayName;
        }
        return result;
      })();

      competitionSelectOptions.value = await createCompetitionSelectOptions(allSelectableCompetitions.value);
      selectedCompetitionUID.value = (() => {
        if (selectedCompetitionUID.value) {
          // pulled from local storage or url or etc, note we only support local storage at this time
          if (competitionSelectOptions.value.find(v => v.value === selectedCompetitionUID.value)) {
            // selection was valid
            return selectedCompetitionUID.value;
          }
        }
        // default of "first in list if we have it"
        return competitionSelectOptions.value[0]?.value || "";
      })()

      divisionSelectOptions.value = await createDivisionSelectOptions(allDivisions.value);
      selectedDivIDs.value = (() => {
        const justDivIDs = divisionSelectOptions.value.map(v => v.value)
        const maybeFromPersistedSelection = justDivIDs.filter(divID => selectedDivIDs.value.indexOf(divID) !== -1)

        if (maybeFromPersistedSelection.length > 0) {
          // Maybe some get dropped, if persisted options are no longer valid
          // If one or more survives the filtering, we'll reuse those.
          return maybeFromPersistedSelection;
        }
        else {
          // persistence was empty, or all persisted values have become invalid
          return divisionSelectOptions.value.map(v => v.value)
        }
      })()

      weekSelectOptions.value = await createWeekSelectOptions();
      selectedWeek.value = (() => {
        const targetComp = allSelectableCompetitions.value.find(v=>v.competitionUID === selectedCompetitionUID.value);
        if (weekSelectOptions.value.find(v => v.value === selectedWeek.value)) {
          // the value from persistence remains valid, we'll reuse it
          return selectedWeek.value;
        }

        if (targetComp) {
          return chooseCurrentWeekFromAvailableWeeks(
            targetComp,
            weekSelectOptions.value.map(v=>v.value)
          ) ?? /* we shouldn't ever need this fallback */ weekSelectOptions.value[0].value;
        }
        else {
          // couldn't find the target competition
          // they were all filtered away? none were sent?
          // current selectedCompetitionUID is "" because of the above reasons?
          return "";
        }
      })()

      fieldSelectOptions.value = await createFieldSelectOptions();
      selectedField.value = (() => {
        if (fieldSelectOptions.value.find(v => v.value === selectedField.value)) {
          // value from persistence was valid, reuse it
          return selectedField.value
        }
        else {
          return ALL_FIELDS_FIELDID;
        }
      })()
    }

    const GAME_EXPANDABLES = ["refereeDetails", "familyConflicts", "adhocCoachInfo"] as const;

    const getGames = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => await getGamesWorker());
    }

    const getGamesWorker = async () : Promise<void> => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        if (
          selectedCompetitionUID.value && selectedDivIDs.value.length > 0 && selectedWeek.value
        ) {
          const competitionUID = selectedCompetitionUID.value;
          const divIDs = [...selectedDivIDs.value]

          const {competitionID} = await Client.getCompetitionByUidOrFail(competitionUID)

          const games = await ilgame
            .getGames(axiosInstance, {
              competitionID,
              startDate: formatDateWithDashes(selectedWeek.value),
              endDate: formatDateWithDashes(addDays(selectedWeek.value, 6).toString()),
              divIDs: divIDs,
              expand: GAME_EXPANDABLES
            })


          if (isAdmin.value) {
            // TODO: don't re-run if competitionUID/divIDs haven't changed
            publishedSchedules.run(() => ilgame.getPublicationDates(freshNoToastLoggedInAxiosInstance(), {competitionUID, divIDs}))
          }

          const competitions = new Map((await getCompetitionsOrFail({ax: axiosInstance})).value.map(v => [v.competitionUID, v]))

          __gamesAndComps.value = {
            games,
            comps: competitions,
          }

          searchComplete.value = true
        }
      });
    }

    /**
     * Given "games by game date", build a new object with the keys inserted in the appropriate order based on the current sort config.
     * This assumes later iteration (by Object.entries() or similar) is by key insertion order, which is typically true for most engines (but not spec guaranteed).
     */
    const sortGamesByGameDate = <T extends {games: AugmentedGame[]}>(gamesByGameDate: {[key: Datelike]: T}) => {
      const keys = Object.keys(gamesByGameDate).sort(sortByDayJS(identity, "seconds", sortConfig.value.topLevelGroupSort));
      const result : {[key: Datelike]: T} = {}
      for (const k of keys) {
        result[k] = gamesByGameDate[k]
      }
      return result;
    }

    const sortGamesByDivGenderAndNum = <T extends {divNum: number, gender: string, games: AugmentedGame[]}>(gamesByDiv: {[divID: Guid]: T}) => {
      const entries = checkedObjectEntries(gamesByDiv).sort(sortByMany(
        sortBy(_ => _[1].divNum, sortConfig.value.topLevelGroupSort),
        sortBy(_ => _[1].gender)
      ));

      const result : {[divGenderAndNum: string]: T} = {}

      for (const [k,v] of entries) {
        result[k] = v
      }

      return result;
    }

    const doCreateRefSignupRequest = async (event: CreateRefSignupRequestEvent) : Promise<void> => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          await ilgame.createRefereeSignupRequest(axiosInstance, event)
          await doReloadGame(event.gameID)
        });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doCancelRefSignupRequest = async (event: CancelRefSignupRequestEvent) : Promise<void> => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const sendConfirmationEmail = shouldSendEmailToRefOnApprovalsAndCancellations.value
          await ilgame.cancelRefereeSignupRequest(axiosInstance, {...event, sendConfirmationEmail})
          await doReloadGame(event.gameID)
        });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doApproveRefAssignment = async (event: ApproveRefAssignmentEvent) : Promise<void> => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const sendConfirmationEmail : boolean = shouldSendEmailToRefOnApprovalsAndCancellations.value
          await ilgame.approveRefereeAssignment(axiosInstance, {...event, sendConfirmationEmail})
          await doReloadGame(event.gameID)
        });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    const doCancelRefAssignment = async (event: CancelRefAssignmentEvent) : Promise<void> => {
      try {
        await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
          const sendConfirmationEmail : boolean = shouldSendEmailToRefOnApprovalsAndCancellations.value
          await ilgame.cancelRefereeAssignment(axiosInstance, {...event, sendConfirmationEmail})
          await doReloadGame(event.gameID)
        });
      }
      catch (err) {
        AxiosErrorWrapper.rethrowIfNotAxiosError(err)
      }
    }

    /**
     * reload a single game
     */
    const doReloadGame = async (gameID: iltypes.Guid) : Promise<void> => {
      // Scan through all the (date -> AugmentedGame[]) to find the AugmentedGame[] containing
      // the target game. We will then update this array with the fresh game obj.
      const targetGamesArray = __gamesAndComps.value

      if (!targetGamesArray) {
        // shouldn't happen
        throw Error("found no array containing the target game")
      }

      const {competitionID} = await Client.getCompetitionByUidOrFail(selectedCompetitionUID.value)

      const freshGame = await ilgame
        .getGame(axiosInstance, {
          competitionID,
          gameID,
          expand: GAME_EXPANDABLES
        })

      if (!freshGame) {
        // maybe should log this, it's 100% unexpected.
        return;
      }

      const spliceIdx = arrayFindIndexOrFail(targetGamesArray.games, game => game.gameID === gameID)
      targetGamesArray.games.splice(spliceIdx, 1, freshGame)
    }


    const approvePendingRefs = async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        try {
          const gameIDsWhereGameHasSomePendingRef = __gamesAndComps
            .value
            .games
            .filter(game => hasSomePendingRef(game))
            .map((game) => game.gameID)

          await approveAllPendingRefAssignments(axiosInstance, {gameIDs: gameIDsWhereGameHasSomePendingRef, sendConfirmationEmail: shouldSendEmailToRefOnApprovalsAndCancellations.value})
          await getGames()
        } catch (err) {
          AxiosErrorWrapper.rethrowIfNotAxiosError(err)
        }
      })
    }

    const getCurrentSchedule = async () => {
      await getGames()
    }

    const forceSetSelectedWeekBasedOnCompetitionStartDayOfWeek = (comp: Competition) => {
      selectedWeek.value = chooseCurrentWeekFromAvailableWeeks(comp, weekSelectOptions.value.map(v => v.value))
        ?? forceCheckedIndexedAccess(weekSelectOptions.value, 0)?.value // didn't find anything, use first available option
        ?? ""; // fallback for no available options, shouldn't happen
    }

    const watchForFormUpdates = () => {
      watch(selectedCompetitionUID, async () => {
        if (!selectedCompetitionUID.value) {
          refSlotOptionsForSelectedCompetition.value = null
          return;
        }

        const config = (await Client.getRefSlotConfigLookup()).find({competitionUID: selectedCompetitionUID.value, divID: ""})

        refSlotOptionsForSelectedCompetition.value = (() => {
          const result : RefSlotOption[] = [];
          for (let i = 0; i < config.numSlots; ++i) {
            result.push({
              name: config[`pos${i+1 as WellKnownRefSlotIndex}Name`] as string,
              // try to carry forward the previous selection;
              // a miss (because the new array is longer than the old array) will yield true.
              selected: forceCheckedIndexedAccess(refSlotOptionsForSelectedCompetition.value ?? [], i)?.selected ?? true
            })
          }
          return result;
        })()
      }, {immediate:true})

      watch(selectedCompetitionUID, async () => {
        weekSelectOptions.value = await createWeekSelectOptions();

        const divs = filterDivisions(allDivisions.value)
        // there could possibly be no matches;
        // clear out divisions select options, then repopulate if some exist
        divisionSelectOptions.value = [];

        if (Object.keys(divs).length) {
          for (const divID of Object.keys(divs)) {
            divisionSelectOptions.value.push({
              label: divs[divID],
              value: divID
            })
          }
        }

        // update selectedDivIDs with respect to the newly computed options
        selectedDivIDs.value = selectedDivIDs.value.filter(divID => !!divisionSelectOptions.value.find(opt => opt.value === divID))
        if (selectedDivIDs.value.length === 0) {
          // if we ended up with no options, select all the currently available options
          selectedDivIDs.value = divisionSelectOptions.value.map(v => v.value)
        }

        const selectedCompetition = allSelectableCompetitions.value.find(comp => comp.competitionUID === selectedCompetitionUID.value)

        if (selectedCompetition) {
          forceSetSelectedWeekBasedOnCompetitionStartDayOfWeek(selectedCompetition)
        }
        else {
          // why didn't we find it?
          // what can we do about it?
          // do nothing here probably leaves the form in a weird state
        }

        __gamesAndComps.value = {
          games: [],
          comps: new Map()
        }
      })

      watch(() => selectedDivIDs.value, async () => {
        if (selectedWeek.value) {
          __gamesAndComps.value = {
            games: [],
            comps: new Map()
          }
          searchComplete.value = false
        }
      }, {deep: true})

      watch(selectedWeek, async () => {
        // no-op
      })

      watch(isAdmin, async () => {
        await initPrimaryComponentState()
      })
    };

    onMounted(async () => {
      await GlobalInteractionBlockingRequestsInFlight.withSpinner(async () => {
        refSlotConfigLookup.value = await Client.getRefSlotConfigLookup()

        await initPrimaryComponentState()

        teams.value = Client.value.teams

        watchForFormUpdates();

        ready.value = true;

        if (hasValidRiskStatus.value) {
          // primarly a dev/testing consideration, not a 2 way binding, just runs on mount
          selectedCompetitionUID.value = (route.query.competitionUID as string) || selectedCompetitionUID.value;
          selectedWeek.value = (route.query.week as string) || selectedWeek.value;
          if (selectedWeek.value) {
            await getGames();
          }
        }
      })
    })

    const selectManyDivsViewModel = (() => {
      const props : SelectMany.Props = reactive({
        selectedKeys: selectedDivIDs,
        options: divisionSelectOptions,
        offerAllOption: true,
      })
      const emits : SelectMany.Emits = {
        checkedOne: (divID, freshIsCheckedValue) => {
          if (freshIsCheckedValue) {
            selectedDivIDs.value.push(divID)
          }
          else {
            const idx = selectedDivIDs.value.indexOf(divID)
            if (idx === -1) {
              /*couldn't find it, nothing to do*/
            }
            else {
              selectedDivIDs.value.splice(idx, 1);
            }
          }
        },
        checkedAll: (freshCheckedAllValue: boolean) => {
          selectedDivIDs.value = freshCheckedAllValue
            ? divisionSelectOptions.value.map(v => v.value)
            : []
        }
      }
      return {props, emits}
    })();

    const selectManyRefSlotsViewModel = (() => {
      const props : SelectMany.Props = reactive({
        selectedKeys: computed(() => refSlotOptionsForSelectedCompetition
          .value
          ?.map((v,i) => [v.selected,i]) // to tuple (bool, index)
          .filter(([b]) => b)            // drop     (false, index)
          .map(([_,i]) => i.toString())  // map      (true, index) -> index.toString()
          ?? []                          // or nothing if there aren't current options available
        ),
        options: computed(() => refSlotOptionsForSelectedCompetition
          .value
          ?.map((v,i) => ({label: v.name, value: i.toString()}))
          ?? [] // or nothing if there aren't any options available
        ),
        offerAllOption: true,
      })
      const emits : SelectMany.Emits = {
        checkedOne: (idx, freshIsCheckedValue) => {
          if (!refSlotOptionsForSelectedCompetition.value) {
            // shouldn't happen
            return;
          }

          const target = forceCheckedIndexedAccess(refSlotOptionsForSelectedCompetition.value, parseIntOrFail(idx));

          if (!target) {
            // shouldn't happen
            throw Error("index out of bounds")
          }

          if (freshIsCheckedValue) {
            target.selected = true;
          }
          else {
            target.selected = false;
          }
        },
        checkedAll: (freshCheckedAllValue: boolean) => {
          if (!refSlotOptionsForSelectedCompetition.value) {
            // shouldn't happen
            return;
          }

          if (freshCheckedAllValue) {
            refSlotOptionsForSelectedCompetition.value.forEach(v => v.selected = true)
          }
          else {
            refSlotOptionsForSelectedCompetition.value.forEach(v => v.selected = false)
          }
        }
      }
      return {props, emits}
    })();

    const refereeScheduleTableHandlers : RefereeScheduleTableEmits = {
      getGames: () => getGames(),
      createRefSignupRequest: event => doCreateRefSignupRequest(event),
      cancelRefSignupRequest: event => doCancelRefSignupRequest(event),
      approveRefAssignment: event => doApproveRefAssignment(event),
      cancelRefAssignment: event => doCancelRefAssignment(event),
      reloadGame: event => doReloadGame(event.gameID),
      changeSortConfig: event => {
        sortConfig.value.perGameColumn = event.column
        sortConfig.value.perGameDir = event.dir
      }
    } as const;

    const hasValidRiskStatus = computed(() => {
      return authService(User.userData?.roles ?? [], "inLeague") || currentUserHasValidRiskStatus()
    });

    return {
      selectedCompetitionUID,
      competitionSelectOptions,
      selectedDivIDs,
      divisionSelectOptions,
      weekSelectOptions,
      fieldSelectOptions,
      selectedWeek,
      selectedField,
      teams,
      isAdmin,
      searchComplete,
      getCurrentSchedule,
      getGames,
      approvePendingRefs,
      refAuth,
      adminAuth,
      keys,
      keyColumns,
      viewAdmin,
      groupedGames,
      ready,
      selectManyDivsViewModel,
      selectManyRefSlotsViewModel,
      refSlotOptionsForSelectedCompetition,
      selectedDivisionsDescriptor,
      shouldSendEmailToRefOnApprovalsAndCancellations,
      refereeScheduleTableHandlers,
      publishedSchedules,
      sortConfig,
      sortableColumnOptions,
      resetSort,
      isInleague: computed(() => User.isInleague),
      hasValidRiskStatus,
      selectedCompetition_refCancelHours,
      selectedCompetition_refCanCancel,
    }
  },
})

function getTeamByID(teams: DeepConst<TeamI[]>, teamID: string) : DeepConst<TeamI> | undefined {
  for (let i = 0; i < teams.length; i++) {
    if (teams[i].teamID === teamID) {
      return teams[i]
    }
  }
  return undefined;
}

function buildGamesByGameDate(teams: DeepConst<TeamI[]>, games: readonly Game[]) : {[key: Datelike]: Game[]} {
  const dividedGames: { [key: Datelike]: Game[] } = {}
  for (let i = 0; i < games.length; i++) {
    const game = games[i]
    const home = getTeamByID(teams, game.home)
    const visitor = getTeamByID(teams, game.visitor)

    // TODO: shouldn't be our responsibility here
    game.homeName = home?.team
    game.visitorName = visitor?.team

    if (dividedGames[games[i].gameDate]) {
      dividedGames[games[i].gameDate].push(game)
    } else {
      dividedGames[games[i].gameDate] = [game]
    }
  }
  return dividedGames
}

function buildGamesByDivID(teams: DeepConst<TeamI[]>, games: readonly Game[]) : {[key: Datelike]: {divNum: number, gender: string, games: Game[]}} {
  const result: {[divID: Guid]: {divNum: number, gender: string, games: Game[]}} = {}

  for (const game of games) {
    const home = getTeamByID(teams, game.home)
    const visitor = getTeamByID(teams, game.visitor)

    // TODO: shouldn't be our responsibility here
    game.homeName = home?.team
    game.visitorName = visitor?.team

    result[game.divID] ??= {divNum: parseIntOrFail(game.divNum), gender: game.divGender || "unknown", games: []}
    result[game.divID].games.push(game)
  }

  return result
}

/**
 * The intent here is mostly "getRefConfigForGame", we could almost change the signature to getRefConfig(store, game)
 * A game has a competitionUID and a divID (are those fields on game ever optional? if so, both? just one?)
 */
function getRefConfig(args: {refConfig: RefSlotConfig, withHeaderLabel: boolean}) {
  const refTitles : QTableBodyCellRefereeVSlotBindingName[] = []
  const refColumns : QTableRefereeColumnDef[] = []

  for (let i = 1; i <= args.refConfig.numSlots; i++) {
    refTitles.push(`body-cell-REF${i}`)
    refColumns.push({
      name: `REF${i}`,
      required: false,
      align: 'left',
      label: args.withHeaderLabel ? args.refConfig[`pos${i as WellKnownRefSlotIndex}Name`] : "",
      field: (row: AugmentedGame) => {
        const game = row.game;

        type RefKey = `ref${WellKnownRefSlotIndex}` | `ref${WellKnownRefSlotIndex}Vol`

        const obj = game[`ref${i}Vol` as RefKey]
          ? (game[`ref${i}Vol` as RefKey])
          : (game[`ref${i}` as RefKey])

        if (obj) {
          if (typeof obj === "string") {
            return ""
          }
          else {
            return `${obj.FirstName} ${obj.LastName}`
          }
        }

        return ""
      },
      sortable: false,
      classes: 'q-table--col-auto-width',
      headerClasses: 'q-table--col-auto-width',
    })
  }

  return {
    refTitles,
    refColumns
  }
}

function buildAugmentedGamesByGameDate(
  refConfigLookup: RefSlotConfigLookup,
  gamesByGameDate: {[key: Datelike]: Game[]},
  compsByCompetitionUID: Map<Guid, Competition>,
  selectedDivisionsUiBlurb: string,
) : {
  [key: Datelike]: {
    refColumns: QTableRefereeColumnDef[],
    showRefPosNamesPerButton: boolean,
    tableTitle: string,
    games: AugmentedGame[]
  }
} {
  const result : {[key: Datelike]: {refColumns: QTableRefereeColumnDef[], showRefPosNamesPerButton: boolean, games: AugmentedGame[], tableTitle: string}} = {};

  for (const datelikeKey of Object.keys(gamesByGameDate)) {
    const games : AugmentedGame[] = []

    for (const game of gamesByGameDate[datelikeKey]) {
      const comp = requireNonNull(compsByCompetitionUID.get(game.competitionUID))
      games.push(buildOneAugmentedGame(refConfigLookup, game, comp))
    }

    if (games.length === 0) {
      continue;
    }

    const {refColumns, showRefPosNamesPerButton} = (() => {
      const uniqueCompDivPairs = [...new Map(games.map(v => {
        const competitionUID = v.game.competitionUID
        const divID = v.game.divID
        return [`${competitionUID}/${divID}`, {competitionUID, divID}]
      })).values()]

      const refSlotConfigDisjointness = getRefSlotConfigDisjointnessInfo(uniqueCompDivPairs.map(v => {
        const slotConfig = refConfigLookup.find(v)
        const refPosNames : string[] = []
        for (let i = 1; i <= slotConfig.numSlots; i++) {
          refPosNames.push(slotConfig[`pos${i as WellKnownRefSlotIndex}Name`])
        }

        return {
          refPosNames,
          sourceRefConfig: slotConfig,
        }
      }));

      if (!refSlotConfigDisjointness) {
        // Shouldn't happen ... if this happens, it means ... there were no ref slot configs ... ?
        // Fallback to a sane default, someone will hopefully complain.
        // We could throw, but it seems possible this is more likely to a misconfiguration (no ref slots)
        // rather than a bug
        return {
          refColumns: getRefConfig({
            refConfig: RefSlotConfigLookup.defaultLookup().find({competitionUID: null, divID: null}),
            withHeaderLabel: true
          }).refColumns,
          showRefPosNamesPerButton: false,
        }
      }

      if (refSlotConfigDisjointness.someRefSlotConfigsAreDisjoint) {
        return {
          refColumns: getRefConfig({
            refConfig: refSlotConfigDisjointness.firstSourceHavingMaxRefPosCount,
            withHeaderLabel: false
          }).refColumns,
          showRefPosNamesPerButton: true,
        }
      }
      else {
        return {
          refColumns: getRefConfig({
            refConfig: refSlotConfigDisjointness.firstSourceHavingMaxRefPosCount,
            withHeaderLabel: true
          }).refColumns,
          showRefPosNamesPerButton: false,
        }
      }
    })()

    result[datelikeKey] = {
      refColumns,
      showRefPosNamesPerButton,
      games,
      tableTitle: (() => {
        const rawBaseDate = games[0].game.gameDate;
        const dateObj = dayjs(rawBaseDate);
        const date = dateObj.isValid() ? dateObj.format("ddd, MMMM Do, YYYY") : (rawBaseDate ?? "");
        return `${date} | ${selectedDivisionsUiBlurb}`;
      })()
    }
  }

  return result;
}

function buildAugmentedGamesByDiv(
  refConfigLookup: RefSlotConfigLookup,
  gamesByDivID: {[divID: Guid]: {gender: string, divNum: number, games: Game[]}},
  compsByCompetitionUID: Map<Guid, Competition>,
) : {
  [divID: Guid]: {
    gender: string,
    divNum: number,
    refColumns: QTableRefereeColumnDef[],
    showRefPosNamesPerButton: false,
    games: AugmentedGame[],
    tableTitle: string,
  }
} {
  const result : {[divID: Guid]: {
    gender: string,
    divNum: number,
    refColumns: QTableRefereeColumnDef[],
    showRefPosNamesPerButton: false,
    games: AugmentedGame[],
    tableTitle: string,
  }} = {};

  for (const datelikeKey of Object.keys(gamesByDivID)) {
    const games : AugmentedGame[] = []

    const obj = gamesByDivID[datelikeKey]
    for (const game of obj.games) {
      const comp = requireNonNull(compsByCompetitionUID.get(game.competitionUID))
      games.push(buildOneAugmentedGame(refConfigLookup, game, comp))
    }

    if (games.length === 0) {
      continue;
    }

    const {refColumns, showRefPosNamesPerButton} = (() => {
      const uniqueCompDivPairs = [...new Map(games.map(v => {
        const competitionUID = v.game.competitionUID
        const divID = v.game.divID
        return [`${competitionUID}/${divID}`, {competitionUID, divID}]
      })).values()]

      // inputs were groupd by div (and implicitly by comp) so there will only be 1
      assertTruthy(uniqueCompDivPairs.length === 1)

      const uniqueCompDivPair = uniqueCompDivPairs[0]

      return {
        refColumns: getRefConfig({
          refConfig: refConfigLookup.find(uniqueCompDivPair),
          withHeaderLabel: true
        }).refColumns,
        showRefPosNamesPerButton: false as const,
      }
    })()

    result[datelikeKey] = {
      divNum: obj.divNum,
      gender: obj.gender,
      refColumns,
      showRefPosNamesPerButton,
      games,
      tableTitle: (() => {
        const rawBaseDate = games[0].game.gameDate;
        const dateObj = dayjs(rawBaseDate);
        const date = dateObj.isValid() ? dateObj.format("ddd, MMMM Do, YYYY") : (rawBaseDate ?? "");
        return `${obj.gender}${obj.divNum} | From ${date}`;
      })(),
    }
  }

  return result;
}

function buildOneAugmentedGame(lookup: RefSlotConfigLookup, game: Game, competition: Competition) : AugmentedGame {
  return reactive({
    game: game,
    competition,
    refConfig: lookup.find(game),
  })
}

function sortConfig_localStorageKey(userID: Guid | null) {
  return `il/userID={${userID}}/ref-schedule-page/sort-state`;
}

function sortConfig_writeToLocalStorage(userID: Guid | null, sortConfig: SortConfig) {
  localStorage.setItem(sortConfig_localStorageKey(userID), JSON.stringify(sortConfig));
}

function sortConfig_freshDefault() : SortConfig {
  return {
    topLevelGroupBy: "byDateTime",
    topLevelGroupSort: "asc",
    perGameColumn: "field",
    perGameDir: "asc",
  }
}

function sortConfig_fromLocalStorageOrDefaul(userID: Guid | null) : SortConfig {
  try {
    const v : unknown = JSON.parse(localStorage.getItem(sortConfig_localStorageKey(userID)) ?? "")
    if (typeof v !== "object" || v === null) {
      return sortConfig_freshDefault();
    }
    const topLevelGroupBy = (v as SortConfig).topLevelGroupBy ?? fail()
    const allGamesByDate = isSortDir((v as SortConfig).topLevelGroupSort) ? (v as SortConfig).topLevelGroupSort : fail();
    const perGameColumn = isGameColumn((v as SortConfig).perGameColumn) ? (v as SortConfig).perGameColumn : fail();
    const perGameDir = isSortDir((v as SortConfig).perGameDir) ? (v as SortConfig).perGameDir : fail();

    return {
      topLevelGroupBy,
      topLevelGroupSort: allGamesByDate,
      perGameColumn,
      perGameDir
    }
  }
  catch {
    return sortConfig_freshDefault();
  }

  function fail() : never {
    throw "fail";
  }
}

async function approveAllPendingRefAssignments(ax: AxiosInstance, args: {gameIDs: Guid[], sendConfirmationEmail: boolean}) : Promise<{gameID: Guid}[]> {
  const response = await ax.post('v1/refereeAssignments/approveAll', {
    gameidlist: args.gameIDs,
    sendConfirmationEmail: args.sendConfirmationEmail
  })
  return response.data.data;
}

const hasSomePendingRef = (game: Game) : boolean => {
  if ((!game.ref1 && game.ref1Vol)
    || (!game.ref2 && game.ref2Vol)
    || (!game.ref3 && game.ref3Vol)
    || (!game.ref4 && game.ref4Vol)
  ) {
    return true;
  }
  else {
    return false;
  }
}
</script>

<style scoped>
@media (min-width: 640px) {
  .tableWidth {
    width: 75vw !important;
  }
}
@media (min-width: 768px) {
  .tableWidth {
    width: 50vw !important;
  }
}
@media (min-width: 1024px) {
  .tableWidth {
    width: 600px !important;
  }
}
</style>
